import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import HeaderMainPage from '../../header/HeaderMainPage';
import Swal from 'sweetalert2';
import { FaQuestionCircle } from 'react-icons/fa';
import { handleAddCompany } from './CompanyDataUtils';

const UpdateCampaign = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  // const [selectedData, setSelectedData] = useState('campaign 1');
  const [description, setDescription] = useState('');
  const [mustHaveInfo, setMustHaveInfo] = useState('');
  const [outputFormat, setOutputFormat] = useState('');
  // const [targetIndustry, setTargetIndustry] = useState(() => {
  //   const savedTargetIndustry = localStorage.getItem('targetIndustry');
  //   return savedTargetIndustry ? JSON.parse(savedTargetIndustry) : [];
  // });
  const [targetIndustry, setTargetIndustry] = useState([]);
  const [targetIndustry2, setTargetIndustry2] = useState('');

  const [targetRegion, setTargetRegion] = useState('');
  const [industryInput, setIndustryInput] = useState('');
  const roboman_api = process.env.REACT_APP_ROBOMAN_API;
  const [currentData, setCurrentData] = useState([]);
  const [newTargetIndustry, setNewTargetIndustry] = useState([]);
  
  
  
  useEffect(() => {
    const currentCamData = JSON.parse(localStorage.getItem('campaign_info'));
    setCurrentData(currentCamData)
    if (currentCamData === null){}
    // Load saved data from localStorage
    const savedName = currentCamData?.campaign_name ?? localStorage.getItem('campaign_name');
    // const savedSelectedData = localStorage.getItem('campaignSelectedData');
    const savedDescription = currentCamData?.core_service?? localStorage.getItem('core_service');
    const savedMustHaveInfo = localStorage.getItem('campaignMustHaveInfo');
    const savedOutputFormat = localStorage.getItem('campaignOutputFormat');
    const savedTargetIndustry = currentCamData?.industry?? JSON.parse(localStorage.getItem('industry'));
    const savedLocation = currentCamData?.location?? localStorage.getItem('location');
    
    if (savedName) setName(savedName);
    // if (savedSelectedData) setSelectedData(savedSelectedData);
    if (savedDescription) setDescription(savedDescription);
    if (savedMustHaveInfo) setMustHaveInfo(savedMustHaveInfo);
    if (savedOutputFormat) setOutputFormat(savedOutputFormat);
    if (savedTargetIndustry) setTargetIndustry(savedTargetIndustry);
    if (savedLocation) setTargetRegion(savedLocation);
  }, []);

  useEffect(() => {
    // Save targetIndustry to localStorage whenever it changes
    localStorage.setItem('targetIndustry', JSON.stringify(targetIndustry));
  }, [targetIndustry]);
  
  const handleExit = () => {
    navigate('/email-status');
  };

  const handleTargetIndustryKeyDown = (e) => {
    if (e.key === 'Enter' && industryInput.trim() !== '') {
      setTargetIndustry([...targetIndustry, industryInput.trim()]);
      setIndustryInput('');
    }
  };

  const handleUpdateCampaign = async () => {
    const access_token = localStorage.getItem('access_token');
    const updateindustry = [...targetIndustry, ...newTargetIndustry];
  
    const requestBody = {
      campaign_name: localStorage.getItem('campaign_name'),
      core_service: localStorage.getItem('campaignDescription'),
      unique_selling_proposition: currentData?.unique_selling_proposition ?? localStorage.getItem('campaignDescription'),
      target_audience: currentData?.target_audience ?? localStorage.getItem('target_audience'),
      problem_solved: currentData?.problem_solved ?? localStorage.getItem('problem_solved'),
      key_benefits: currentData?.key_benefits ?? JSON.parse(localStorage.getItem('key_benefits')),
      primary_goal_of_outreach_campaign: currentData?.primary_goal_of_outreach_campaign ?? localStorage.getItem('primary_goal_of_outreach_campaign'),
      ideal_client: currentData?.ideal_client ?? JSON.parse(localStorage.getItem('ideal_client')),
      success_measurement: currentData?.success_measurement ?? localStorage.getItem('success_measurement'),
      industry: updateindustry,
      location: localStorage.getItem('location'),
      must_have_info: localStorage.getItem('campaignMustHaveInfo'),
    };
  
    try {
      const campaign_id = localStorage.getItem('campaign_id');
      const response = await fetch(`${roboman_api}/campaign/${campaign_id}/update-details`, {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': access_token,
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("Campaign updated successfully", data.status);
        return true;
      } else {
        console.error('Failed to update campaign. Response:', response);
        return false;
      }
    } catch (error) {
      console.error('Error in handleUpdateCampaign:', error);
      return false;
    }
  };
  

  const handleUpdateContentFormat = async () => {
    const access_token = localStorage.getItem('access_token');
    const requestBody = {
      email_format: localStorage.getItem('campaignOutputFormat'),
      linkedin_msg_format: 'Write a message of 100 words',
    };
  
    try {
      const campaign_id = localStorage.getItem('campaign_id');
      const response = await fetch(`${roboman_api}/campaign/${campaign_id}/update-content-format`, {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': access_token,
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("Content format updated successfully", data.status);
        return true;
      } else {
        console.error('Failed to update content format. Response:', response);
        return false;
      }
    } catch (error) {
      console.error('Error in handleUpdateContentFormat:', error);
      return false;
    }
  };
  
  const updateCampaignDataInLocalStorage = (userCampaign) => {
    const selectedCampaignId = localStorage.getItem('campaign_id');
    
    // Find the specific campaign by ID
    const updateData = userCampaign.find(
      (campaign) => campaign.campaign_id === selectedCampaignId
    );
  
    if (!updateData) {
      console.error("Campaign with the specified ID not found.");
      return;
    }
  
    // Save the relevant fields into localStorage
    localStorage.setItem('campaign_info', JSON.stringify(updateData.campaign_info));
    localStorage.setItem('campaign_name', updateData.campaign_info.campaign_name);
    localStorage.setItem('campaignOutputFormat', updateData.email_format);
    localStorage.setItem('campaignDescription', updateData.campaign_info.core_service);
  
    // console.log("Updated campaign data saved in localStorage:", {
    //   campaign_info: updateData.campaign_info,
    //   selectedCampaign: updateData.campaign_name,
    //   campaignOutputFormat: updateData.email_format,
    //   campaignDescription: updateData.campaign_info.core_service,
    // });
  };

  const fetchCampaignDataFromAPI = async (access_token) => {
    try {
      const response = await fetch(`${roboman_api}/campaign/personal`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': access_token,
        },
      });

      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
        return []
      }
      const data = await response.json();
      return data;

    } catch (error) {
      console.error('Error fetching user campaign data:', error);
      return null;
    }
  };

  const handleUpdateCampaignAndContent = async () => {
    try {
      const campaignUpdateSuccess = await handleUpdateCampaign();
      const contentUpdateSuccess = await handleUpdateContentFormat();
      await handleAddCompany();
      if (campaignUpdateSuccess && contentUpdateSuccess) {
        const access_token = localStorage.getItem('access_token');
        const userCampaign = await fetchCampaignDataFromAPI(access_token);

        if (userCampaign) {
          updateCampaignDataInLocalStorage(userCampaign);
        }
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Campaign and content updated successfully',
          confirmButtonColor: '#3085d6',
        }).then(() => {
          navigate('/email-status');
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'One or both updates failed. Please try again.',
          confirmButtonText: 'Try Again',
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error('Error during the update process:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred. Please try again later.',
        confirmButtonText: 'Try Again',
        allowOutsideClick: false,
      });
    }
  };


  

  // Update state setters to also save to localStorage
  const updateName = (value) => {
    setName(value);
    localStorage.setItem('campaign_name', value);
  };

  // const updateSelectedData = (value) => {
  //   setSelectedData(value);
  //   localStorage.setItem('campaignSelectedData', value);
  // };

  const updateDescription = (value) => {
    setDescription(value);
    localStorage.setItem('campaignDescription', value);
  };

  const updateMustHaveInfo = (value) => {
    setMustHaveInfo(value);
    localStorage.setItem('campaignMustHaveInfo', value);
  };

  const updateOutputFormat = (value) => {
    setOutputFormat(value);
    localStorage.setItem('campaignOutputFormat', value);
  };

  const updateTargetIndustry2 = (value) => {
    setTargetIndustry2(value);
    localStorage.setItem('industry', value);
  };
  
  const updateTargetRegion = (value) => {
    setTargetRegion(value);
    localStorage.setItem('location', value);
  };

  const IconWithPopup = ({ message }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div 
        className="relative inline-block"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="text-lg cursor-pointer ml-1">
          <FaQuestionCircle /> {/* Replace 🔔 with FaQuestionCircle */}
        </div>
        {isHovered && (
          <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-[300px] p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">
            {message}
          </div>
        )}
      </div>
    );
  };

  const must_have_info = "This is the field that you will insert some sensitive information, which must be shown exactly when generating mail such as Name, Email, Telephone, Address, etc."
  const desire_output_format = "This is the field that you will define the the output format of the email with some criteria such as the length, the tone, etc."
  
  const handleCancel = async () => {
    navigate('/email-status');
  }
  const handleNewIndustryKeyDown = (e) => {
    if (e.key === 'Enter' && industryInput.trim() !== '') {
      setNewTargetIndustry([...newTargetIndustry, industryInput.trim()]);
      setIndustryInput('');
    }
  };


  return (
    <div className="bg-white min-h-screen flex flex-col items-center">
      
      <HeaderMainPage />

      <div className="w-4/6 h-full items-start rounded-lg shadow-md my-5 mt-[30px] rounded-md">
        <div className="w-full bg-gray-100 h-[50px] border rounded-md flex items-center justify-center">
          <div className="w-full flex justify-between items-center pl-8 pr-8">
            <h2 className="text-xl font-bold">Update Campaign</h2>
            <button onClick={handleExit} className="text-gray-500 hover:text-gray-700 text-2xl">&times;</button>
          </div>
        </div>

        <form className="mt-4 mr-8 ml-8 mb-4 space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-0">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => updateName(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
              placeholder='Input your campaign name'
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => updateDescription(e.target.value)}
              // required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-24 resize-none bg-gray-100"
              placeholder='Give some description'
            />
          </div>
          <div>
            <div className="flex items-center">
                <label htmlFor="mustHaveInfo" className="block text-sm font-medium text-gray-700 mb-1">Must have information:</label>
                <IconWithPopup message={must_have_info}/>
            </div>

            <textarea
              id="mustHaveInfo"
              value={mustHaveInfo}
              onChange={(e) => updateMustHaveInfo(e.target.value)}
              // required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-24 resize-none bg-gray-100"
              placeholder='My contact number is...' 
            />
          </div>
          <div>
            <div className="flex items-center">
            <label htmlFor="outputFormat" className="block text-sm font-medium text-gray-700 mb-1">Desired output format:</label>
                <IconWithPopup message={desire_output_format}/>
            </div>
            
            <textarea
              type="text"
              id="outputFormat"
              value={outputFormat}
              onChange={(e) => updateOutputFormat(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-20 resize-none bg-gray-100"
              placeholder='Write an email which have length of 200 words, have professional and business tone...' 
            />
          </div>
          {/* <div>
            <label htmlFor="targetIndustry" className="block text-sm font-medium text-gray-700 mb-1">Target Industry:</label>
            <input
              type="text"
              id="targetIndustry"
              value={targetIndustry2}
              onChange={(e) => updateTargetIndustry2(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
            />
          </div> */}


          <div>
            <label
              htmlFor="targetIndustry"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Target Industry:
            </label>
            <div className="flex flex-wrap gap-2 p-3 border border-gray-300 rounded-md focus-within:ring-2 focus-within:ring-blue-500 bg-gray-100">
              {/* Display old target industries (non-deletable) */}
              {Array.isArray(targetIndustry) && targetIndustry.length > 0 ? (
                targetIndustry.map((industry, index) => (
                  <span
                    key={index}
                    className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm flex items-center"
                  >
                    {industry}
                  </span>
                ))
              ) : (
                <span className="text-gray-500">No industries available</span>
              )}

              {/* Display new target industries (deletable) */}
              {newTargetIndustry.map((industry, index) => (
                <span
                  key={`new-${index}`}
                  className="bg-green-600 text-white px-3 py-1 rounded-full text-sm flex items-center"
                >
                  {industry}
                  <button
                    type="button"
                    onClick={() =>
                      setNewTargetIndustry((prev) =>
                        prev.filter((_, idx) => idx !== index)
                      )
                    }
                    className="ml-1 text-white hover:text-gray-200"
                  >
                    ×
                  </button>
                </span>
              ))}

              {/* Input for adding new industries */}
              <input
                type="text"
                id="targetIndustry"
                value={industryInput}
                onChange={(e) => setIndustryInput(e.target.value)}
                onKeyDown={handleNewIndustryKeyDown}
                className="flex-grow outline-none bg-gray-100"
                placeholder="Add a new target industry and press Enter"
              />
            </div>
          </div>


          <div>
            <label htmlFor="targetRegion" className="block text-sm font-medium text-gray-700 mb-1">Target Region:</label>
            <input
              type="text"
              id="targetRegion"
              value={targetRegion}
              onChange={(e) => updateTargetRegion(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
            />
          </div>
        </form>

        <div className="flex justify-center mt-1 mb-4">
          <button 
            className="bg-[#223F9E] text-white py-2 px-8 rounded-full hover:bg-blue-700 transition-colors duration-200 mr-4"
            onClick={handleUpdateCampaignAndContent}
          >
            Update
          </button>
          <button 
            className="bg-[#223F9E] text-white py-2 px-8 rounded-full hover:bg-blue-700 transition-colors duration-200"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateCampaign;
