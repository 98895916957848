import React, { useState } from 'react';
import {OnboardingHeaderPart, LargeImagePlaceholder} from '../../header/OnboardingHeader';
import { useNavigate } from 'react-router-dom';
import video4 from '../../assets/page4_female_openai.mp4';
import Swal from 'sweetalert2';


const OnboardingSelectPlan = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const navigate = useNavigate();

  // Function to handle selection of Intuicon Database plan
  const handleIntuiconDatabaseSelect = () => {
    setSelectedPlan("Intuicon Database");
    localStorage.setItem('selectedPlan', "Intuicon Database");
    console.log("Intuicon Database plan selected");
    navigate('/onboardingcampmatch')
  };

  // Function to handle selection of Linkedin plan
  const handleLinkedinSelect = () => {
    setSelectedPlan("Linkedin");
    localStorage.setItem('selectedPlan', "Linkedin");
    console.log("Linkedin plan selected");
    // navigate('/onboardingcampmatch')

    // Show SweetAlert
    Swal.fire({
      title: 'Feature in Development',
      text: 'This feature is currently in development. Please check back later.',
      icon: 'info',
      confirmButtonText: 'Okay',
    });
  };

  // Function to handle selection of Best Match-More AI filtering plan
  const handleBestMatchSelect = () => {
    setSelectedPlan("Best Match-More AI filtering");
    localStorage.setItem('selectedPlan', "Best Match-More AI filtering");
    console.log("Best Match-More AI filtering plan selected");
    // navigate('/onboardingcampmatch')

    // Show SweetAlert
    Swal.fire({
      title: 'Feature in Development',
      text: 'This feature is currently in development. Please check back later.',
      icon: 'info',
      confirmButtonText: 'Okay',
    });
  };

  // Reusable Button component
  const Button = ({ className, onClick, text }) => <button className={className} onClick={onClick}>{text}</button>;

  // PlanOption component to display each plan
  const PlanOption = ({ title, features, onSelect }) => (
    <div className="bg-white shadow-md rounded-lg p-4 flex flex-col items-start w-[450px] h-[170px] justify-between relative">
      {/* Title and Logo */}
      <div className="flex items-center mb-4 justify-between w-full">
        <div className="flex items-center">
          <div className="w-8 h-8 bg-gray-400 rounded-md mr-3"></div>
          <h3 className="text-sm font-semibold text-left">{title}</h3>
        </div>
        {/* Choose Button */}
        <Button 
          onClick={onSelect} 
          className="text-white font-semibold text-sm bg-[#223F9E] py-1 px-3 rounded-full" text="Choose" />
      </div>
      
      <ul className="list-disc list-inside flex-col text-left w-full flex justify-center ml-5 mb-10">
        {features.map((feature, index) => (
          <li key={index} className="text-gray-700 text-sm">{feature}</li>
        ))}
      </ul>
    </div>
  );

  // SelectionBox component to display all plan options
  const SelectionBox = () => {
    const plans = [
      { 
        title: "Intuicon Database", 
        features: ["Searching in the Database of Intuicon"],
        onSelect: handleIntuiconDatabaseSelect
      },
      { 
        title: "Linkedin", 
        features: ["Searching in Linkedin"],
        onSelect: handleLinkedinSelect
      },
      { 
        title: "Best Match-More AI filtering", 
        features: ["Seaching with AI Filtering"],
        onSelect: handleBestMatchSelect
      },
    ];

    return (
      <main className="flex-grow flex flex-col justify-center items-center p-8 bg-transparent rounded-md">
        <div className="flex flex-col space-y-10">
          {plans.map((plan, index) => (
            <PlanOption key={index} title={plan.title} features={plan.features} onSelect={plan.onSelect} />
          ))}
        </div>
      </main>
    );
  };

  // Main component render
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <OnboardingHeaderPart />
      <div className="flex w-full h-[20px] mt-5 mb-5 justify-center item-center">
        <h1 className="text-3xl font-bold text-left text-black">Choose Your Plan</h1>
      </div>
      
      <div className="flex justify-center items-start flex-grow pt-10">
        <div className="flex w-[1200px] min-h-[600px]">
          <LargeImagePlaceholder videomain={video4}/>
          <div className="w-[600px] h-[600px] bg-gray-100 flex flex-col justify-center"><SelectionBox /></div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingSelectPlan;

// Note: This component allows users to select a plan from three options.
// Each plan has its own selection handler function.
// The selected plan is stored in both component state and localStorage.
// The UI updates to show which plan is currently selected.
// Navigation to the next page after plan selection is not implemented here and should be handled elsewhere.
