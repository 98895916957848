import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import HeaderMainPage from '../../header/HeaderMainPage';
import Swal from 'sweetalert2';
import { fetchCampaignCompanies } from '../managingPage/CompanyDataUtils';
import { FaQuestionCircle, FaEnvelope, FaLinkedin} from 'react-icons/fa';
import ExplainDISC from '../utils/ExplainDISC';
import ExplainOCEAN from '../utils/ExplainOCEAN';

const getStatusStyle = (status) => {
  switch (status) {
    case 1: // Not Started
      return { style: 'bg-red-100 text-red-600', text: 'Not Started' };
    case 2: // Read
      return { style: 'bg-yellow-100 text-yellow-600', text: 'Read' };
    case 3: // Sent
      return { style: 'bg-green-100 text-green-600', text: 'Sent' };
    case 4: // Waiting to Review
      return { style: 'bg-blue-100 text-blue-600', text: 'Waiting to Review' };
    case 5: // Replied
      return { style: 'bg-orange-100 text-orange-600', text: 'Replied' };
    case 6: // Replied
      return { style: 'bg-orange-100 text-black', text: 'Loading' };
    case 7: // Replied
      return { style: 'bg-orange-100 text-red-600', text: 'Fail to Send' };
    default: // Other statuses
      return { style: 'bg-transparent text-black' };
  }
};

const PromptBox = ({ isVisible, promptText, setPromptText, handleAutoSend, position, promptBoxRef }) => {
  // NOTE: Adjust these values to change the position of the prompt box
  const offsetX = -220; // Positive values move the box right, negative values move it left
  const offsetY = 10; // Positive values move the box down, negative values move it up

  return (
    <div 
      ref={promptBoxRef}
      className={`fixed bg-white p-4 rounded-lg shadow-lg w-80 z-50 transition-all duration-300 ease-in-out ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4 pointer-events-none'
      }`}
      style={{
        top: `${Math.min(position.top + position.height + offsetY, window.innerHeight - 300)}px`,
        left: `${Math.min(Math.max(position.left + offsetX, 20), window.innerWidth - 340)}px`,
        maxWidth: 'calc(100vw - 40px)',
        maxHeight: 'calc(100vh - 40px)',
      }}
    >
      <textarea
        className="w-full h-32 p-2 mb-4 bg-gray-100 rounded-md resize-none"
        value={promptText}
        onChange={(e) => setPromptText(e.target.value)}
        placeholder="Enter your prompt here..."
      />
      <div className="flex justify-center">
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-colors duration-200"
          onClick={handleAutoSend}
        >
          Auto Send
        </button>
      </div>
    </div>
  );
};

const EmailStatus = () => {
  // Real data = {"company_email","company_id", "rep_email","content_subject", "email_confirmation_status_id","updated_at","campaign_id","company_name","rep_name","industry","content","created_at"}

  const senderConfig = {
    email: "tuananh.tlhht@gmail.com",
    name: localStorage.getItem("nickname")
  };

  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});

  const [showPromptBox, setShowPromptBox] = useState(false);
  const [promptText, setPromptText] = useState('');
  const [promptButtonPosition, setPromptButtonPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const promptButtonRef = useRef(null);
  const promptBoxRef = useRef(null);
  const [campaignState, setCampaignState] = useState(null);
  const isLnkActive = localStorage.getItem('lnk_act') === 'true'; // Check the localStorage value
  let Campaign_Name = localStorage.getItem("campaign_name");

  // Check if both "campaignName" and "selectedCampaign" exist
  // if (localStorage.getItem("selectedCampaign")) {
  //   // If both exist, use "selectedCampaign"
  //   Campaign_Name = localStorage.getItem("selectedCampaign");
  // } 
  
  const roboman_api = process.env.REACT_APP_ROBOMAN_API;
  const [showActionMenu, setShowActionMenu] = useState(false);
  const ActionMenuRef = useRef(null);

  // State to store fetched data
  const [localCompaniesData, setLocalCompaniesData] = useState([]);
  const campaign_id = localStorage.getItem("campaign_id")
  const hasRunGenerateSelected = useRef(false);
  const [isDataReady, setIsDataReady] = useState(false)

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     navigate('/my-campaign'); // Redirect to Page A when back is pressed
  //   };

  //   window.addEventListener('popstate', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('popstate', handleBeforeUnload);
  //   };
  // }, [navigate]);

  // useEffect(() => {
  //   if (companiesData) {
  //     console.log("data user in storage:", companiesData);
  //     setLocalCompaniesData(companiesData); // Set data from context
  //   } else {
      
  //     console.log("No companies data found, setting empty array.");
  //     setLocalCompaniesData([]); // Fallback to empty array
  //   }
  //   setIsLoading(false); // Data is ready
  // }, [companiesData]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOCEANModalOpen, setIsOCEANModalOpen] = useState(false);

  // Open the DISC modal
  const handleOpenDISCModal = () => {
    setIsModalOpen(true);
  };

  // Close the DISC modal
  const handleCloseDISCModal = () => {
    setIsModalOpen(false);
  };

  // Open the OCEAN modal
  const handleOpenOCEANModal = () => {
    setIsOCEANModalOpen(true);
  };

  // Close the OCEAN modal
  const handleCloseOCEANModal = () => {
    setIsOCEANModalOpen(false);
  };

  const getRandomItem = (list, count) => {
    const shuffled = [...list].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  useEffect(() => {
    const fetchData = async () => {
      const campaignId = localStorage.getItem("campaign_id"); // Replace with actual campaign ID
      const page = 1;  // The page you want to fetch
      const pageSize = 50; // The number of items per page
      const authToken = localStorage.getItem("access_token"); // Your bearer token
      const data = await fetchCampaignCompanies(campaignId, page, pageSize, authToken);
      // console.log(data)
      

      if (data === null) {
        setLocalCompaniesData([]);
      } else {
        // const updatedData = data.map((record) => {
        //   const shouldPopulate = Math.random() < 0.5; // 50% chance to populate or leave blank
        //   return {
        //     ...record,
        //     DISC_profile: shouldPopulate ? getRandomItem(["D", "I", "S", "C"], 1) : [], // Either one random item or empty
        //     OCEAN_profile: shouldPopulate ? getRandomItem(["O", "C", "E", "A", "N"], 3) : [], // Either three random items or empty
        //   };
        // });
  
        // setLocalCompaniesData(updatedData);
        setLocalCompaniesData(data)
      }
      setIsDataReady(true);
      
      console.log("Data have save to use...")
  };

  fetchData();
  }, []);

  useEffect(() => {
    // console.log("data user in storage:", companiesData)
    // console.log("data user in local:", localCompaniesData)
    
    const storedCampaignState = localStorage.getItem('campaignState');
    setCampaignState(storedCampaignState);

    if (storedCampaignState === 'Confirm' && isDataReady && localCompaniesData.length > 0 && !hasRunGenerateSelected.current) {
      handleConfirmState();
      hasRunGenerateSelected.current = true;
      }
    // } else if (storedCampaignState === 'Cancel') {
    //   handleCancelState();
    // }
  }, [isDataReady, localCompaniesData]);

  useEffect(() => {
    const updatePromptButtonPosition = () => {
      if (promptButtonRef.current) {
        const rect = promptButtonRef.current.getBoundingClientRect();
        setPromptButtonPosition({
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
          height: rect.height
        });
      }
    };

    const handleClickOutside = (event) => {
      if (showPromptBox && promptBoxRef.current && !promptBoxRef.current.contains(event.target) && event.target !== promptButtonRef.current) {
        setShowPromptBox(false);
      }
    };

    updatePromptButtonPosition();
    window.addEventListener('resize', updatePromptButtonPosition);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', updatePromptButtonPosition);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPromptBox]);

  const [shouldSendMail, setShouldSendMail] = useState(false);

  useEffect(() => {
    if (shouldSendMail) {
      handleSendSelected(true);
      setShouldSendMail(false); // Reset flag to prevent re-triggering
      console.log("Finish");
    }
  }, [localCompaniesData, shouldSendMail]);

  const handleConfirmState = async () => {
    console.log("Start Auto Generate Mail");
    

    console.log("Updated company data:", localCompaniesData); // This still shows state data
    await handleGenerateSelected(true);
    console.log("Start Auto Send Mail");

    // await handleSendSelected(true); // Set flag to trigger sending mails in `useEffect`
    setShouldSendMail(true);
  };
  // const handleConfirmState = async () => {
  //   console.log("Start Auto Generate Mail")
  //   await handleGenerateSelected(true);

  //   console.log(dummyData) 
  //   console.log("Start Auto Send Mail")
  //   await handleSendSelected(true);
  //   console.log("Complete")
  // };


  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    const newSelectedRows = {};
    localCompaniesData.forEach(row => {
      newSelectedRows[row.company_id] = isChecked;
    });
    setSelectedRows(newSelectedRows);
  };

  const handleRowSelect = (id) => {
    const newSelectedRows = { ...selectedRows, [id]: !selectedRows[id] };
    console.log("Selected Row: ", newSelectedRows)
    setSelectedRows(newSelectedRows);
    setSelectAll(Object.values(newSelectedRows).every(Boolean) && Object.keys(newSelectedRows).length === localCompaniesData.length);
  };

  const handlePromptClick = () => {
    setShowPromptBox(!showPromptBox);
  };

  const handleAutoSend = async () => {
    console.log("Auto Send clicked. Prompt text:", promptText);
    // Here you would typically save the promptText to your state or send it to an API
    // setShowPromptBox(false);
    // Reset the prompt text after sending
    // setPromptText('');

    // Iterate through each row and send emails
    const updatedData = await Promise.all(
        localCompaniesData.map(async (row) => {
        // Check if content is empty before sending
        if (row.content === null) {
          // If content is empty, update the status to 'Failed to Send'
          return { ...row, email_confirmation_status_id: 'Failed to Send' };
        }

        try {
          // Try sending the email
          await sendEmail(row);  // Assuming sendEmail is an async function

          // Update the status to 'Sent' if successful
          return { ...row, email_confirmation_status_id: 2 };
        } catch (error) {
          // If there's an error, update the status to 'Failed to Send'
          return { ...row, email_confirmation_status_id: 'Failed to Send' };
        }
      })
    );

    // Update the state with the new data
    setLocalCompaniesData(updatedData);
  };

  const handleMailReply = () => {
    navigate("/mail-reply")
  }

  // New function to handle status click
  // const handleStatusClick = (status, id) => {
  //   if (status === 'Replied') {
  //     navigate('/mail-reply', { state: { id } });
  //   }
  // };
  // New functions to handle button clicks
  const handleMailboxClick = () => {
    navigate("/mailbox"); // Replace with the actual path to your Mailbox page
  };

  const handleCampaignClick = () => {
    // localStorage.removeItem("campaign_id");
    navigate("/my-campaign"); // Replace with the actual path to your Campaign page
  };

  const handleDeleteClick = () => {
    const newData = localCompaniesData.filter(row => !selectedRows[row.email_confirmation_status_id]);
    setLocalCompaniesData(newData);
    setSelectedRows({});
    setSelectAll(false);
  };

  const generateEmail = async(reply_name, disc_label, ocean_label) => {

    // receiverName = ""
    const productName = localStorage.getItem('core_service')
    const keyBenefits = localStorage.getItem('key_benefits')
    const problemSolved = localStorage.getItem('problem_solved')
    const mustHaveInfo = localStorage.getItem('campaignMustHaveInfo')
    const outputFormat = localStorage.getItem('campaignOutputFormat')
    const sendername = localStorage.getItem('nickname')

    const mail_generate_prompt = `
    Name of sender or email writer: ${sendername}
    Name of receiver: ${reply_name}
    Product name: ${productName}
    Key Benefits: ${keyBenefits}
    Problem Solved: ${problemSolved}
    Must have information: ${mustHaveInfo}
    Desired Output Format: ${outputFormat}
    If not found any thing in Must have information and Desired Output Format,
    write the letter as default, using sender name at the ending.`;
    
    //

    // console.log(mail_generate_prompt)

    try {
      const response = await fetch(`${roboman_api}/campaign/email/generation`, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Authorization': localStorage.getItem('access_token'),
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              "input_str": mail_generate_prompt.trim(),
              "ocean_label":ocean_label,
              "disc_label":disc_label,
          }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('API Error Details:', errorData);
        console.error('Status Code:', response.status);
        return false;   
      } 
      
      const responseData = await response.text(); // Get raw response
      // console.log('Full Response:', responseData);
      const responseText = JSON.parse(responseData);
      // console.log('Text Response:', responseText.generated_email);
      const responseText1 = responseText.generated_email
      // console.log('Text Response:', responseText1);
      
      let parsedData;
      try {
        parsedData = JSON.parse(responseText1); // Parse the raw string to JSON
        localStorage.setItem('parsedData', responseText1)
        return true
      } catch (parseError) {
        console.error('Error parsing response JSON:', parseError);
        return false; // Return null values if parsing fails
      }

    } catch (error) {
        console.error('Error during email generation:', error);
        return false;
    }
  };

  // console.log(JSON.parse(localStorage.getItem('parsedData'))["content"])
  const generateLinkedInMessage = async(reply_name, linkedInlink) => {

    // receiverName = ""
    const productName = localStorage.getItem('core_service')
    const keyBenefits = localStorage.getItem('key_benefits')
    const problemSolved = localStorage.getItem('problem_solved')
    const sendername = localStorage.getItem('nickname')

    const linkedin_generate_prompt = `
    Write a short linkedin message using the information below
    Name of sender or email writer: ${sendername}
    Name of receiver: ${reply_name}
    Product name: ${productName}
    Key Benefits: ${keyBenefits}
    Problem Solved: ${problemSolved}`;

    // If not found any thing in Must have information and Desired Output Format,
    // write the letter as default, using sender name at the ending.`;

    try {
      const response = await fetch(`${roboman_api}/campaign/email/generation`, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Authorization': localStorage.getItem('access_token'),
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              "input_str": linkedin_generate_prompt.trim(),
              "rep_linkedin":linkedInlink
          }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('API Error Details:', errorData);
        console.error('Status Code:', response.status);
        return false;   
      } 
      
      const responseData = await response.text(); // Get raw response
      // console.log('Full Response:', responseData);
      const responseText = JSON.parse(responseData);
      // console.log('Text Response:', responseText.generated_email);
      const responseText1 = responseText.generated_email
      console.log('Text Response:', responseText1);
      
      let parsedData;
      try {
        parsedData = JSON.parse(responseText1); // Parse the raw string to JSON
        localStorage.setItem('parsedData', responseText1)
        return true
      } catch (parseError) {
        console.error('Error parsing response JSON:', parseError);
        return false; // Return null values if parsing fails
      }

    } catch (error) {
        console.error('Error during linkedin message generation:', error);
        return false;
    }
  };

  const handleGenerate = async (company_id) => {
    Swal.fire({
      title: 'Generating Email',
      text: 'Please wait while the email content is being generated.',
      icon: 'info',
      allowOutsideClick: false, // Prevent closing the alert by clicking outside
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      },
    });

    if (campaignState !== "Cancel") return;
    console.log("start generating")
    // Update status to "Generating..."
    setLocalCompaniesData((prevData) =>
      prevData.map((row) =>
        row.company_id === company_id ? { ...row, email_confirmation_status_id: 6, content: "Generating..." } : row
      )
    );
    const replyname = localCompaniesData.find((row) => row.company_id === company_id)?.rep_name;
    const disc_label = localCompaniesData.find((row) => row.company_id === company_id)?.disc_label ?? [];
    const ocean_label = localCompaniesData.find((row) => row.company_id === company_id)?.ocean_label ?? [];
    //Generate Email
    try {
      const result = await generateEmail(replyname, disc_label, ocean_label); // Simulate email content generation
      const mailData =
        result === true
          ? JSON.parse(localStorage.getItem("parsedData")) || {}
          : {};

      const mailSubject = mailData.head.replace(/^Subject:\s*/, '') || "";
      const mailContent = mailData.content || "";
      
      if (mailContent !== "" && mailContent !== ""){
        await updateEmailStatus(campaign_id,company_id,mailSubject,mailContent,4)
      } else {
        await updateEmailStatus(campaign_id,company_id,mailSubject,mailContent,1)
      }

        // Update content and status
      // setLocalCompaniesData((prevData) =>
      //   prevData.map((row) =>
      //     row.company_id === company_id
      //       ? {
      //           ...row,
      //           content_subject: mailSubject,
      //           content: mailContent,
      //           email_confirmation_status_id: 4,
      //         }
      //       : row
      //   )
      // );


      await updateData()
      // Dismiss the loading alert and show success message
      Swal.fire({
        title: 'Success!',
        text: 'Email content has been successfully generated and updated.',
        icon: 'success',
      });
    } catch (error) {
      console.error("Error generating email content:", error);

      // Update status to error
      setLocalCompaniesData((prevData) =>
        prevData.map((row) =>
          row.company_id === company_id ? { ...row, status: "Generation Failed" } : row
        )
      );

      // Dismiss the loading alert and show error message
      Swal.fire({
        title: 'Error!',
        text: 'Failed to generate email content. Please try again.',
        icon: 'error',
      });
    }

    //Generate Linkedin Message
    // try {
    //   const result_mess = await generateLinkedInMessage(replyname, linkedInLink); // Simulate email content generation
    //   const messageData =
    //   result_mess === true
    //       ? JSON.parse(localStorage.getItem("parsedMessData")) || {}
    //       : {};

    //   const messageContent = messageData.content || "";
      
    //   if (messageContent !== ""){
    //     await updateLinkedinMessage(campaign_id,company_id,messageContent)
    //   } else {
    //     await updateLinkedinMessage(campaign_id,company_id,messageContent)
    //   }

    //   await updateData()
    //   // Dismiss the loading alert and show success message
    //   Swal.fire({
    //     title: 'Success!',
    //     text: 'Linkedin Message content has been successfully generated and updated.',
    //     icon: 'success',
    //   });
    // } catch (error) {
    //   console.error("Error generating Linkedin Message content:", error);

    //   // Dismiss the loading alert and show error message
    //   Swal.fire({
    //     title: 'Error!',
    //     text: 'Failed to generate Linkedin Message content. Please try again.',
    //     icon: 'error',
    //   });
    // }
  };

  const sendEmail = async (rowData) => {
    
    try {
      // Determine the recipient email based on the provided logic
      const emailTo = rowData.company_contact.rep_email || rowData.company_contact.company_email || null;
  
      if (!emailTo) {
        console.error("No valid email available to send the message.");
        return; // Exit if there's no valid email to send
      }
      const response = await fetch(`${roboman_api}/campaign/email`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': localStorage.getItem('access_token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email_from: senderConfig.email,
          name_from: senderConfig.name,
          email_to: rowData.company_email,
          name_to: rowData.company_name,
          subject: rowData.content_subject,
          content: rowData.content,
          html_content: '',
        })
      });

      if (!response.ok) {
        const errorData = await response.text(); // Capture raw text for error handling
        console.error('API Error:', errorData);
        return;
      } else {
        console.log('Email sending successful');
      }

      // return await response.json();
    } catch (error) {
      console.log('Email sending error:', error);
    }
  };

  const handleSend = async (company_id) => {
    if (campaignState === 'Cancel') {
      const rowData = localCompaniesData.find((row) => row.company_id === company_id);
  
      if (!rowData.content.trim() || rowData.content === null || !rowData.content_subject.trim() || rowData.content_subject === null) {
        Swal.fire({
          icon: 'error',
          title: 'Empty Content',
          text: 'Please add content and subject before sending the email',
          confirmButtonColor: '#3085d6',
        });
        return;
      }
  
      try {
        // Simulate sending email
        sendEmail(rowData);

        //
        await updateEmailStatus(campaign_id,company_id,rowData.content_subject, rowData.content, 3);
  
        // Update status to 'Sent' on success
        const updatedData = localCompaniesData.map((row) =>
          row.company_id === company_id ? { ...row, email_confirmation_status_id: 3 } : row
        );
        // setLocalCompaniesData(updatedData);
      } catch (error) {
        //
        await updateEmailStatus(campaign_id,company_id,rowData.content_subject, rowData.content, 7);

        // Update status to 'Failed to Send' on error
        const updatedData = localCompaniesData.map((row) =>
          row.company_id === company_id ? { ...row, email_confirmation_status_id: 7 } : row
        );
        // setLocalCompaniesData(updatedData);
        
      }
      await updateData()
    }
  };

  const updateData = async () => {
      const page = 1;  // The page you want to fetch
      const pageSize = 20; // The number of items per page
      const authToken = localStorage.getItem("access_token"); // Your bearer token
      const data = await fetchCampaignCompanies(campaign_id, page, pageSize, authToken);

      // setLocalCompaniesData(data);

      if (data === null) {
        setLocalCompaniesData([]);
      } else {
        // const updatedData = data.map((record) => {
        //   const shouldPopulate = Math.random() < 0.5; // 50% chance to populate or leave blank
        //   return {
        //     ...record,
        //     DISC_profile: shouldPopulate ? getRandomItem(["D", "I", "S", "C"], 1) : [], // Either one random item or empty
        //     OCEAN_profile: shouldPopulate ? getRandomItem(["O", "C", "E", "A", "N"], 3) : [], // Either three random items or empty
        //   };
        // });
  
        // setLocalCompaniesData(updatedData);
        setLocalCompaniesData(data);
      }
      setIsDataReady(true);
      console.log("Data have updated...")
  };

  const [showPopup, setShowPopup] = useState(false);
  const [editingSubject, setEditingSubject] = useState('');
  const [editingContent, setEditingContent] = useState('');
  const [editingId, setEditingId] = useState(null);

  const handleReview = async(company_id) => {
    const rowData = localCompaniesData.find((item) => item.company_id === company_id);
    if (rowData) {
      // Open a popup for editing content
      setEditingSubject(rowData.content_subject);
      setEditingContent(rowData.content);
      setEditingId(company_id);
      setShowPopup(true);
  
      // Update status to 'Read'
      // const updatedData = localCompaniesData.map((item) =>
      //   item.company_id === company_id ? { ...item, email_confirmation_status_id: 3 } : item
      // );
      // setLocalCompaniesData(updatedData);
      await updateEmailStatus(campaign_id,company_id,rowData.content_subject,rowData.content,3)
      
    }
  };

  const handleAcceptChange = async() => {

    setShowPopup(false);
    await updateEmailStatus(campaign_id, editingId, editingSubject, editingContent,2)
    await updateData();
    
    console.log("mail has been changed")
    
  };

  const handleCancel = async() => {
    setShowPopup(false);
    await updateEmailStatus(campaign_id, editingId, editingSubject,editingContent,2)
    await updateData();
    
    setEditingContent('');
    setEditingId(null);
  };

  const [isPopUpAddContact, setPopUpAddContact] = useState(false);
  const [newContact, setNewContact] = useState({
    company_name: '',
    industry: '',
    company_email: '',
    company_linkedin: '',
    rep_name: '',
    rep_email: '',
    rep_linked: '',
  });

  const handleAddContactClick = () => {
    setPopUpAddContact(true);
  };

  const handleAddContact = async () => {
     // Check if required fields are filled
    const { company_name, industry, company_email, rep_email } = newContact;
    if (!company_name || !industry || !company_email || !rep_email) {
      Swal.fire({
        icon: 'warning',
        title: 'Missing Information',
        text: 'Please fill in all the required fields: Company Name, Industry, Company Email, and Reply Email.',
        confirmButtonText: 'OK',
      });
      return; // Stop the function execution if validation fails
    }

    try {
      // Prepare the contact data
      const contactData = {
        company_name: newContact.company_name,
        industry: newContact.industry,
        company_email: newContact.company_email,
        company_linkedin: newContact.company_linkedin,
        rep_name: newContact.rep_name,
        rep_email: newContact.rep_email,
        rep_linkedin: newContact.rep_linked,
      };

      console.log("new contact data", contactData)
  
      // Send the data to your API (replace with actual API endpoint)
      const response = await fetch(`${roboman_api}/campaign/${localStorage.getItem("campaign_id")}/companies/add`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Authorization': localStorage.getItem('access_token'), // Use your actual token here
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contactData),
      });
  
      if (!response.ok) {
        console.error('Failed to add contact:', response);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'There was a problem adding the contact. Please try again later.',
          confirmButtonText: 'OK',
        });
        return;
      }
  
      // Optionally, get the response data if needed
      const data = await response.json();
  
      // Reset the form state
      setNewContact({
        company_name: '',
        industry: '',
        company_email: '',
        company_linkedin: '',
        rep_name: '',
        rep_email: '',
        rep_linked: '',
      });
  
      
  
      // Show success alert
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'The contact has been successfully added.',
        confirmButtonText: 'OK',
      });
    
    
    } catch (error) {
      console.error('Error adding contact:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'There was a problem adding the contact. Please try again later.',
        confirmButtonText: 'OK',
      });
    }
    await updateData();
  };
  

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setNewContact((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const newContactWithId = { ...newContact, id: Date.now() }; // Add a unique ID
    setLocalCompaniesData((prevData) => [newContactWithId, ...prevData]); // Add to the top of the list
    setNewContact({ company_name: '', industry: '', company_email: '', company_linkedin: '', rep_name: '', rep_email:'', rep_linked:''}); // Reset form
    setPopUpAddContact(false); // Close the pop-up
  };

  const handlePopUpClose = () => {
    setPopUpAddContact(false);
  };

  useEffect(() => {
    console.log("Updated selectedRows:", selectedRows);
  }, [selectedRows])

  const handleGenerateSelected = async (all = false) => {
    if (showActionMenu === true) {toggleActionMenu()}
    Swal.fire({
      title: 'Generating Email',
      text: 'Please wait while the email content is being generated.',
      icon: 'info',
      allowOutsideClick: false, // Prevent closing the alert by clicking outside
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      },
    });

    // Delay function defined inside handleGenerateSelected
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    // Select rows to generate based on `all` parameter
    const selectedIds = all ? localCompaniesData.map((row) => row.company_id) : Object.keys(selectedRows).filter((company_id) => selectedRows[company_id]);

    if (selectedIds.length === 0) {
      console.log("No rows selected.");
      return;
    }

    console.log(selectedIds)

    // Filter out rows that are already "Waiting to Review"
    const rowsToGenerate = selectedIds.filter(
      (company_id) => {
        const row = localCompaniesData.find((row) => row.company_id === company_id);
        // console.log(`Checking row with ID ${id}:`, row);
        return row && row.email_confirmation_status_id !== 4 && row.email_confirmation_status_id !== 2; // Only process rows not in "Waiting to Review" or "Send" status
      }
    );

    console.log("check row", rowsToGenerate)

    if (rowsToGenerate.length === 0) {
      console.log("All selected rows are already in 'Waiting to Review' status.");
      return;
    }
  
    const batchSize = 3; // Adjust batch size as needed
    
    // console.log(`Processing ${rowsToGenerate.length} rows in batches of ${batchSize}...`);
  
    // Process in batches
    for (let i = 0; i < rowsToGenerate.length; i += batchSize) {
      const batch = rowsToGenerate.slice(i, i + batchSize);
  
      console.log(`Processing batch: ${batch}`);
  
      await Promise.all(
        batch.map(async (company_id) => {
          // const companyName = localCompaniesData.find((row) => row.company_id === company_id)?.company_name;
          const replyname = localCompaniesData.find((row) => row.company_id === company_id)?.rep_name;
          const disc_label = localCompaniesData.find((row) => row.company_id === company_id)?.ocean_label ?? [];
          const ocean_label = localCompaniesData.find((row) => row.company_id === company_id)?.disc_label ?? [];
          // Update status to "Generating..."
          setLocalCompaniesData((prevData) =>
            prevData.map((row) =>
              row.company_id === company_id ? { ...row, email_confirmation_status_id: 6 } : row
            )
          );
  
          try {
            const result = await generateEmail(replyname, disc_label, ocean_label); // Generate email for this row
            const mailData =
              result === true
                ? JSON.parse(localStorage.getItem("parsedData")) || {}
                : {};
  
            const mailSubject = mailData.head || "";
            const mailContent = mailData.content || "";
            
            if (mailContent !== "" && mailContent !== ""){
              await updateEmailStatus(campaign_id,company_id,mailSubject,mailContent,4)
            } else {
              await updateEmailStatus(campaign_id,company_id,mailSubject,mailContent,1)
            }

            // Update content and status
            // setLocalCompaniesData((prevData) =>
            //   prevData.map((row) =>
            //     row.company_id === company_id
            //       ? {
            //           ...row,
            //           content_subject: mailSubject,
            //           content: mailContent,
            //           email_confirmation_status_id: 4,
            //         }
            //       : row
            //   )
            // );

            // await updateEmailStatus(campaign_id,company_id,mailSubject,mailContent,4)
          } catch (error) {
            console.error(`Error generating email for row ${company_id}:`, error);
  
            // Update status to "Generation Failed"
            localCompaniesData((prevData) =>
              prevData.map((row) =>
                row.company_id === company_id ? { ...row, email_confirmation_status_id: "Generation Failed" } : row
              )
            );
          }
        })
      );
      await delay(2000); 
    }
    await updateData();
    setSelectedRows({});
    setSelectAll(false);
    console.log("selectedrows after generated", selectedRows)
    console.log("All batches processed!");
  };

  const handleSendSelected = async (all = false) => {
    if (showActionMenu === true) {toggleActionMenu()}

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
    const selectedIds = all
      ? localCompaniesData.map((row) => row.company_id)
      : Object.keys(selectedRows).filter((company_id) => selectedRows[company_id]);
  
    if (selectedIds.length === 0) {
      console.log("No rows selected.");
      return;
    }
  
    const rowsToSend = selectedIds.filter((company_id) => {
      const row = localCompaniesData.find((row) => row.company_id === company_id);
      console.log(row)
      return row && 
        (row.email_confirmation_status_id === 2 || row.email_confirmation_status_id === 4) && 
        (row.content && row.content.trim() !== "" && row.content !== null) && 
        (row.content_subject && row.content_subject.trim() !== "" && row.content_subject !== null);
    });

    console.log("Row to send:", rowsToSend)
  
    if (rowsToSend.length === 0) {
      Swal.fire({
        icon: "error",
        title: "No Emails Ready",
        text: "Please check again the mail. Mails maybe are not created or sent",
        confirmButtonColor: "#3085d6",
      });
      return;
    }

    const batchSize = 3;
    console.log(`Processing ${rowsToSend.length} rows in batches of ${batchSize}...`);
  
    const updatedLocalComData = [...localCompaniesData]; // Create a new copy of the data
    let successCount = 0;
    let failureCount = 0;

    Swal.fire({
      title: "Loading...",
      text: "Please wait while sendind mail.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    for (let i = 0; i < rowsToSend.length; i += batchSize) {
      const batch = rowsToSend.slice(i, i + batchSize);
  
      await Promise.all(
        batch.map(async (company_id) => {
          const index = updatedLocalComData.findIndex((row) => row.company_id === company_id);
          if (index === -1) return;
  
          const rowData = updatedLocalComData[index];
  
          if (!rowData.content.trim()) {
            updatedLocalComData[index] = { ...rowData, email_confirmation_status_id: 1 };
            failureCount++;
            return;
          }
  
          try {
            await sendEmail(rowData); // Replace with your actual email function
            // updatedLocalComData[index] = { ...rowData, email_confirmation_status_id: 2 };
            await updateEmailStatus(campaign_id, company_id, rowData.content_subject, rowData.content, 3)
            successCount++;
          } catch (error) {
            console.error(`Error sending email for row ${company_id}:`, error);
            // updatedLocalComData[index] = { ...rowData, email_confirmation_status_id: 7 };
            await updateEmailStatus(campaign_id, company_id, rowData.content_subject, rowData.content, 7)
            failureCount++;
          }
        })
      );
  
      await delay(2000);
    }
    Swal.close();
    await updateData();
    // setLocalCompaniesData(updatedLocalComData); // Update state once after processing
    setSelectedRows({});
    setSelectAll(false);

    // Final summary message
    if (failureCount === 0) {
      Swal.fire({
        icon: "success",
        title: "All Emails Sent Successfully",
        text: `Successfully sent ${successCount} emails.`,
        confirmButtonColor: "#3085d6",
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Some Emails Failed to Send",
        text: `Successfully sent ${successCount} emails. ${failureCount} emails failed. Please review and try again.`,
        confirmButtonColor: "#3085d6",
      });
    }
  };

  const handleDeleteSelected = async () => {
    if (showActionMenu === true) { 
      toggleActionMenu(); 
    }
  
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
    // Get selected row IDs from the selectedRows object
    const selectedIds = Object.keys(selectedRows).filter((company_id) => selectedRows[company_id]);
  
    if (selectedIds.length === 0) {
      console.log("No rows selected.");
      Swal.fire({
        icon: "error",
        title: "No Rows Selected for Deletion",
        text: "Please select rows to delete.",
        confirmButtonColor: "#3085d6",
      });
      return;
    }
  
    const rowsToDelete = selectedIds.filter((company_id) => {
      const row = localCompaniesData.find((row) => row.company_id === company_id);
      return row; // Only keep valid rows
    });
  
    console.log("Rows to delete:", rowsToDelete);
  
    if (rowsToDelete.length === 0) {
      Swal.fire({
        icon: "error",
        title: "No Rows Found for Deletion",
        text: "There were no rows found to delete. Please select valid rows.",
        confirmButtonColor: "#3085d6",
      });
      return;
    }
  
    const batchSize = 3;
    console.log(`Processing ${rowsToDelete.length} rows in batches of ${batchSize}...`);
  
    let successCount = 0;
    let failureCount = 0;
  
    Swal.fire({
      title: "Deleting...",
      text: "Please wait while deleting records.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    // Deleting in batches
    for (let i = 0; i < rowsToDelete.length; i += batchSize) {
      const batch = rowsToDelete.slice(i, i + batchSize);
  
      await Promise.all(
        batch.map(async (company_id) => {
          try {
            const response = await fetch(`${roboman_api}/campaign/${localStorage.getItem('campaign_id')}/companies/${company_id}/delete`, {
              method: 'DELETE',
              headers: {
                'accept': 'application/json',
                'Authorization': localStorage.getItem('access_token'),
              },
            });
  
            if (!response.ok) {
              throw new Error(`Failed to delete company_id: ${company_id}`);
            }
  
            console.log(`Successfully deleted company_id: ${company_id}`);
            successCount++;
          } catch (error) {
            console.error(`Error deleting company_id ${company_id}:`, error);
            failureCount++;
          }
        })
      );
  
      await delay(2000); // Delay between batch requests
    }
  
    Swal.close();
    await updateData(); // Refresh the data after deletion
    setSelectedRows({}); // Reset selected rows
    setSelectAll(false); // Reset select all
  
    // Final summary message
    if (failureCount === 0) {
      Swal.fire({
        icon: "success",
        title: "All Records Deleted Successfully",
        text: `Successfully deleted ${successCount} records.`,
        confirmButtonColor: "#3085d6",
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Some Records Failed to Delete",
        text: `Successfully deleted ${successCount} records. ${failureCount} deletions failed. Please review and try again.`,
        confirmButtonColor: "#3085d6",
      });
    }
  };

  const handleSendLinked = async (company_id) => {
    console.log("send linkedin message")
    if (campaignState === 'Cancel') {
      const rowData = localCompaniesData.find((row) => row.company_id === company_id);
  
      if (!rowData.linkedin_message.trim() || rowData.linkedin_message === null || !rowData.content_subject.trim() || rowData.content_subject === null) {
        Swal.fire({
          icon: 'error',
          title: 'Empty Messsage',
          text: 'Please add content before sending the message',
          confirmButtonColor: '#3085d6',
        });
        return;
      }  
      if (!rowData.company_contact.rep_linkedin.trim() || rowData.company_contact.rep_linkedin === null) {
        Swal.fire({
          icon: 'error',
          title: 'Empty Linkedin',
          text: 'This company have no linkedin reply information',
          confirmButtonColor: '#3085d6',
        });
        return;
      }

      const requestBody = {
        recipient_address: rowData.company_contact.rep_linkedin,
        subject: rowData.content_subject,
        content: rowData.content,
      };
      

      try {
        const response = await fetch(`${roboman_api}/campaign/send-linked-message`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': localStorage.getItem('access_token'),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
  
        const result = await response.json();
        await Swal('Success', 'Message sent successfully!', 'success');
        console.log(result);

      } catch (error) {
        await Swal('Error', error.message, 'error');
        
      }
      await updateData()
    }
  };
  

  const toggleActionMenu = () => {
    setShowActionMenu(!showActionMenu);
  };

   // Conditional Rendering: Show loading or data
  // if (localCompaniesData===null) {
  //   return <div>Loading...</div>; // Show loading message or spinner
  // }  

  const updateEmailStatus = async (campaignId, companyId, contentSubject, content, emailConfirmationStatusId) => {
    try {
      const response = await fetch(`${roboman_api}/campaign/companies/update-email-status`, {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Authorization': localStorage.getItem("access_token"),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          company_id: companyId,
          content_subject: contentSubject,
          content: content,
          email_confirmation_status_id: emailConfirmationStatusId
        })
      });
   
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
        return null;
      }
   
      const data = await response.json();
      console.log("Update Successfully")
      return data;
      
    } catch (error) {
      console.error('Error updating email status:', error);
      return null;
    }
  }

  const updateLinkedinMessage = async (campaignId, companyId, linkedin_message) => {
    try {
      const response = await fetch(`${roboman_api}/campaign/companies/update-linkedin-msg-status`, {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Authorization': localStorage.getItem("access_token"),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          company_id: companyId,
          linkedin_message: linkedin_message,
        })
      });
   
      if (!response.ok) {
        console.error(`Cannot update linkedin messsage:`);
        return null;
      }
   
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error updating linkedin messsage:', error);
      return null;
    }

  }

  const IconWithPopup = ({ message }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div 
        className="relative inline-block"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="text-lg cursor-pointer ml-2 mb-4">
          <FaQuestionCircle /> {/* Replace 🔔 with FaQuestionCircle */}
        </div>
        {isHovered && (
          <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-[300px] p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10">
            {message}
          </div>
        )}
      </div>
    );
  };

  const handleUpdateCampaign = () => {
    navigate("/update-campaign")

  }

  const handleProfileClick = (company_id) => {
    const linkedInLink = localCompaniesData.find((row) => row.company_id === company_id)?.rep_linkedin_address;
    // console.log("LinkedInlink", linkedInLink)
    localStorage.setItem("LinkedInlink", linkedInLink)
    localStorage.setItem("company_id", company_id)
    navigate("/viewinsight")
  }

  return (
    <div className="bg-white min-h-screen flex flex-col items-center">
      <HeaderMainPage />

      <div className="w-full max-w-[1700px] mx-auto mt-10 bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="flex justify-between items-center p-6 bg-gray-50">
          <h2 className="text-2xl font-bold text-black">{Campaign_Name}</h2>
          <div className="flex flex-wrap items-center gap-4">
            <button onClick={handleUpdateCampaign} className="px-4 py-2 bg-white text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200">Update Campaign</button>
            <button onClick={handleAddContactClick} className="px-4 py-2 bg-white text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200">Add Contact</button>
            <button onClick={handleMailboxClick} className="px-4 py-2 bg-white text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200">Mail box</button>
            {/* <button onClick={handleCampaignClick} className="px-4 py-2 bg-white text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200">Campaigns</button> */}
            {/* <button onClick={handleDeleteClick} className="px-4 py-2 bg-white text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200">Delete</button> */}
            <div className="relative" ref={ActionMenuRef}>
              <button onClick={toggleActionMenu} className="px-4 py-2 bg-white text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200">
                Action <span className="ml-1">▼</span>
              </button>
              {showActionMenu && (
                <div className="absolute top-full left-0 bg-white border border-gray-200 rounded-md shadow-lg z-30 mt-1">
                  <button onClick={() => handleGenerateSelected(false)} className="block w-[150px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-t-md font-semibold">Generate</button>
                  <button onClick={() =>handleSendSelected(false)} className="block w-[150px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-b-md font-semibold">Send</button>
                  <button onClick={handleDeleteSelected} className="block w-[150px] h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-b-md font-semibold">Delete</button>
                </div>
              )}
            </div>
            <button className="px-4 py-2 bg-white text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200">Config</button>
            <button 
              ref={promptButtonRef}
              className="px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors duration-200" 
              onClick={handlePromptClick}
            >
              Prompt
            </button>
          </div>
          
        </div>
        
        <div className="overflow-x-auto">
          <div className="max-h-[calc(100vh-300px)] overflow-y-auto">
            <table className="w-full">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                  </th>
                  <th className="w-32 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
                  <th className="w-32 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Industry</th>
                  <th className="w-32 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Representative</th>
                  <th className="w-32 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={handleOpenDISCModal}>DISC Profile <FaQuestionCircle  className="inline-block ml-1" size={12}/> </th>
                  <th className="w-90 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={handleOpenOCEANModal}>OCEAN Profile <FaQuestionCircle  className="inline-block ml-1" size={12}/> </th>
                  <th className="w-90 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Content</th>
                  <th className="w-48 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="w-48 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {localCompaniesData.map(row => (
                  <tr key={row.company_id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="checkbox"
                        checked={selectedRows[row.company_id] || false}
                        onChange={() => handleRowSelect(row.company_id)}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 truncate max-w-[128px]" title={row.company_name}>
                        {row.company_name}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 truncate max-w-[128px]" title={row.industry}>
                        {row.industry}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {/* <div className="text-sm text-gray-500 truncate max-w-[256px]" title={row.company_contact?.company_email || row.company_contact?.rep_email || ""}>
                        {row.company_contact?.company_email || row.company_contact?.rep_email || ""}
                      </div> */}
                      <div className="text-sm text-gray-500 truncate max-w-[128px]" title={row.company_contact?.rep_name || ""}>
                        {row.rep_name || ""}
                      </div>
                    </td>
                    {/* DISC and OCEAN profile */}
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 truncate w-[60px]" onClick={() => handleProfileClick(row.company_id)}>
                        {row.disc_label && row.disc_label.length > 0
                          ? row.disc_label.map((item, index) => (
                              <span key={`disc-${index}`} className="inline-block px-2 py-1 bg-gray-200 text-gray-700 rounded cursor-pointer">
                                {item}
                              </span>
                            ))
                          : <span className="inline-block px-2 py-1 bg-gray-200 text-gray-700 rounded cursor-pointer">&nbsp;</span>} {/* Blank box if no data */}
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 truncate w-[100px]" onClick={() => handleProfileClick(row.company_id)}>
                        {row.ocean_label && row.ocean_label.length > 0
                          ? row.ocean_label.map((item, index) => (
                              <span key={`ocean-${index}`} className="inline-block px-2 py-1 bg-gray-200 text-gray-700 rounded cursor-pointer">
                                {item}
                              </span>
                            ))
                          : <span className="inline-block px-2 py-1 bg-gray-200 text-gray-700 rounded cursor-pointer">&nbsp;</span>} {/* Blank box if no data */}
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 truncate w-[200px]" title={row.content}>
                        {row.content}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap w-[50px]">
                      <span 
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          typeof row.email_confirmation_status_id === 'number'
                            ? getStatusStyle(row.email_confirmation_status_id).style
                            : ''
                        }`}
                      >
                        <span
                          className="w-2 h-2 rounded-full mr-2 mt-1.5"
                          style={{ backgroundColor: 'currentColor' }}
                        ></span>
                        {typeof row.email_confirmation_status_id === 'string'
                          ? row.email_confirmation_status_id // Show the text directly
                          : getStatusStyle(row.email_confirmation_status_id).text // Show mapped text
                        }
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <div className="flex space-x-3">
                        <button
                          onClick={() => handleGenerate(row.company_id)}
                          className={`rounded-md w-[80px] h-[25px] ${
                            campaignState === 'Confirm' || [3, 4, 6].includes(row.email_confirmation_status_id)
                              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                              : 'bg-yellow-500 text-white hover:bg-yellow-600'
                          }`}
                          disabled={campaignState === 'Confirm' || [3, 4, 6].includes(row.email_confirmation_status_id)}
                        >
                          Generate
                        </button>

                        <button
                          onClick={() => handleReview(row.company_id)}
                          className={`rounded-md w-[80px] h-[25px] ${
                            campaignState === 'Confirm' || row.content === null || [1, 3, 6].includes(row.email_confirmation_status_id)
                              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                              : 'bg-yellow-500 text-white hover:bg-yellow-600'
                          }`}
                          disabled={campaignState === 'Confirm' || row.content === null || [1, 3, 6].includes(row.email_confirmation_status_id)}
                        >
                          Review
                        </button>

                        <button
                          onClick={() => handleSend(row.company_id)}
                          className={`flex justify-center items-center rounded-md w-[50px] h-[25px] ${
                            campaignState === 'Confirm' || row.content === null || [1, 3, 6].includes(row.email_confirmation_status_id)
                              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                              : 'bg-blue-500 text-white hover:bg-blue-600'
                          }`}
                          disabled={campaignState === 'Confirm' || row.content === null || [1, 3, 6].includes(row.email_confirmation_status_id)}
                        >
                          <FaEnvelope
                            className={`text-lg ${
                              campaignState === 'Confirm' || row.content === null || [1, 3, 6].includes(row.email_confirmation_status_id)
                                ? 'text-gray-500'
                                : 'text-white'
                            }`}
                          />
                        </button>

                        <button
                          onClick={() => handleSendLinked(row.company_id)}
                          className={`flex justify-center items-center rounded-md w-[50px] h-[25px] ${
                            !isLnkActive || campaignState === 'Confirm' || row.content === null || [1, 3, 6].includes(row.email_confirmation_status_id)
                              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                              : 'bg-blue-500 text-white hover:bg-blue-600'
                          }`}
                          disabled={
                            !isLnkActive || campaignState === 'Confirm' || row.content === null || [1, 3, 6].includes(row.email_confirmation_status_id)
                          }
                        >
                          <FaLinkedin
                            className={`text-lg ${
                              !isLnkActive || campaignState === 'Confirm' || row.content === null || [1, 3, 6].includes(row.email_confirmation_status_id)
                                ? 'text-gray-500'
                                : 'text-white'
                            }`}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* DISC Modal */}
        <ExplainDISC isOpen={isModalOpen} closeModal={handleCloseDISCModal} />

        {/* OCEAN Modal */}
        <ExplainOCEAN isOpen={isOCEANModalOpen} closeModal={handleCloseOCEANModal} />

      </div>
      <PromptBox
        isVisible={showPromptBox}
        promptText={promptText}
        setPromptText={setPromptText}
        handleAutoSend={handleAutoSend}
        position={promptButtonPosition}
        promptBoxRef={promptBoxRef}
      />

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg md:w-[900px] md:h-[750px]">
            <h3 className="text-xl font-bold mb-4">Edit Email Content</h3>
            {/* <textarea
              value={editingSubject}
              onChange={(e) => setEditingContent(e.target.value)}
              rows={6}
              className="w-full h-[100px] border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-600"
            >Editing Subject</textarea> */}
            <div> 
              <label htmlFor="mailsubject" className="block text-sm font-medium text-gray-700 mb-1">Subject:</label>
              <textarea
                value={editingSubject}
                onChange={(e) => setEditingSubject(e.target.value)}
                rows={6}
                className="w-full h-[50px] border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-600"
              ></textarea>
            </div>
            <div> 
            <label htmlFor="mailcontent" className="block text-sm font-medium text-gray-700 mb-1">Content:</label>
              <textarea
                value={editingContent}
                onChange={(e) => setEditingContent(e.target.value)}
                rows={6}
                className="w-full h-[500px] border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-600"
              ></textarea>
            </div>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                onClick={handleCancel}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleAcceptChange}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      )}

      {isPopUpAddContact && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-[700px] bg-white p-6 rounded-lg shadow-lg">
            <div className="flex items-center"> 
              <h2 className="text-xl font-bold mb-4">Add New Contact</h2>
              <IconWithPopup className="mb-4" message={"Please provide at least the Company Name, Industry, Company Email and Reply Email for sending email"}/>
            </div>
            <form onSubmit={handleFormSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Left Column */}
                <div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">
                      Company Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="company_name"
                      value={newContact.company_name}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your company name"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">
                      Industry <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="industry"
                      value={newContact.industry}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your industry"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">
                      Company Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      name="company_email"
                      value={newContact.company_email}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your company email"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Company LinkedIn</label>
                    <input
                      type="text"
                      name="company_linkedin"
                      value={newContact.company_linkedin}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your company LinkedIn"
                    />
                  </div>
                </div>

                {/* Right Column */}
                <div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Reply Name <span className="text-red-500">*</span> </label>
                    <input
                      type="text"
                      name="rep_name"
                      value={newContact.rep_name}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your company Reply Name"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Reply Email <span className="text-red-500">*</span> </label>
                    <input
                      type="email"
                      name="rep_email"
                      value={newContact.rep_email}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your company Reply Email"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Reply LinkedIn</label>
                    <input
                      type="text"
                      name="rep_linked"
                      value={newContact.rep_linked}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded"
                      placeholder="Your company Reply LinkedIn"
                    />
                  </div>
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-end space-x-4 mt-4">
                <button
                  type="button"
                  onClick={handlePopUpClose}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-full"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700"
                  onClick={handleAddContact}
                >
                  Add
                </button>
              </div>
            </form>

          </div>
        </div>
        )}

    </div>
  );
};

export default EmailStatus;