import Swal from 'sweetalert2'; // Ensure you have SweetAlert2 installed in your project

// Function to convert audio data to base64
export const fetchAndConvertToBase64 = async (url) => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
            },
        });

        if (!response.ok) {
            Swal.fire({
                icon: 'error',
                title: 'Fetch Error',
                text: `HTTP error! status: ${response.status}`,
                confirmButtonText: 'OK',
            });
            return null;
        }

        const arrayBuffer = await response.arrayBuffer();
        const base64 = btoa(
            new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        return base64;
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Conversion Error',
            text: 'Error fetching or converting audio file',
            confirmButtonText: 'OK',
        });
        return null;
    }
};

// Function to call Simli API and get HLS URL
export const simliapiFetchAPI = async (audioBase64Data) => {
    const data = {
        simliAPIKey: process.env.REACT_APP_SIMLI_API_KEY,
        faceId: process.env.REACT_APP_SIMLI_FACEID_FEMALE,
        audioBase64: audioBase64Data,
        audioFormat: 'mp3',
        audioSampleRate: 24000,
        audioChannelCount: 1,
        videoStartingFrame: 0,
    };

    try {
        const response = await fetch('https://api.simli.ai/audioToVideoStream', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            Swal.fire({
                icon: 'error',
                title: 'Simli API Error',
                text: `HTTP error! status: ${response.status}`,
                confirmButtonText: 'OK',
            });
            return null;
        }

        const result = await response.json();
        let url = result.hls_url;

        // Replace 'http' with 'https'
        url = url.replace('http://', 'https://');

        return url;
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Simli API Error',
            text: error.message,
            confirmButtonText: 'OK',
        });
        return null;
    }
};

// Function to fetch MP3 file and convert it to PCM16
export const fetchAndReturnPCM16 = async (url, targetSampleRate = 16000) => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
            },
        });

        if (!response.ok) {
            Swal.fire({
                icon: 'error',
                title: 'Fetch Error',
                text: `HTTP error! status: ${response.status}`,
                confirmButtonText: 'OK',
            });
            return null;
        }

        // Fetch the MP3 file as an ArrayBuffer
        const arrayBuffer = await response.arrayBuffer();

        // Decode the MP3 data
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const originalSampleRate = audioContext.sampleRate;
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        // Resample to target sample rate if needed
        const resampledBuffer = await resampleAudioBuffer(audioBuffer, targetSampleRate);

        // Convert to PCM16 format
        const pcm16Data = convertToPCM16(resampledBuffer);

        return pcm16Data; // Return the PCM16 audio data
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Audio Error',
            text: 'Error fetching or processing the audio file',
            confirmButtonText: 'OK',
        });
        return null;
    }
};

// Function to resample the audio buffer to a specific sample rate
const resampleAudioBuffer = async (audioBuffer, targetSampleRate) => {
    const offlineContext = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        Math.ceil((audioBuffer.length * targetSampleRate) / audioBuffer.sampleRate),
        targetSampleRate
    );

    const source = offlineContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(offlineContext.destination);
    source.start(0);

    return offlineContext.startRendering();
};

// Function to convert Float32Array to PCM16
const convertToPCM16 = (audioBuffer) => {
    const pcmData = new Int16Array(audioBuffer.length * audioBuffer.numberOfChannels);
    let offset = 0;

    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
        const channelData = audioBuffer.getChannelData(channel);
        for (let i = 0; i < channelData.length; i++) {
            const value = Math.max(-1, Math.min(1, channelData[i])); // Clamp between -1 and 1
            pcmData[offset++] = value < 0 ? value * 0x8000 : value * 0x7FFF; // Convert to PCM16
        }
    }

    return pcmData;
};

