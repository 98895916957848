import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import HeaderMainPage, {newCampaign} from '../../header/HeaderMainPage';
import IconMail from '../../assets/icon_mail.svg';
import IconCampaign from '../../assets/icon_campaign.svg';
import IconHeart from '../../assets/icon_heart.svg';
import { useData } from '../../authorize/DataContext';

const roboman_api = process.env.REACT_APP_ROBOMAN_API;
const Button = ({ onClick, className, text }) => (
  <button onClick={onClick} className={`bg-blue-800 ${className}`}>
    {text}
  </button>
);

const getCampaignsStats = async (setCampaigns) => {
  // This function will be implemented later to fetch real data
  // return 123;
  try {
    const response = await fetch(`${roboman_api}/campaign/personal`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': localStorage.getItem('access_token'), // Replace 'code' with your actual bearer token
      },
    });

    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setCampaigns(data); // Save the data into state
    // localStorage.setItem('campaignData', JSON.stringify(data)); // Store campaign data in local storage
    // console.log('Campaign data set in local storage:', data);
    return data.length; // Return the number of campaigns
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    return 0; // Return 0 if there's an error
  }
};

const StatisticPanel = ({ campaignsData }) => {
  const navigate = useNavigate();
  const [emailStats, setEmailStats] = useState({ sent: 0, total: 0 });
  const [campaignsStats, setCampaignsStats] = useState(0);
  const [companyStats, setCompanyStats] = useState(0);
  const [campaigns, setCampaigns] = useState([]);

  // useEffect(() => {
  //   setEmailStats(getEmailSentStats());
  //   // setCampaignsStats(getCampaignsStats());
  //   setSubscribersStats(getSubscribersStats());

  //   const fetchCampaigns = async () => {
  //     const numberOfCampaigns = await getCampaignsStats(setCampaigns);
  //     setCampaignsStats(numberOfCampaigns);
  //   };

  //   // Store campaign data in local storage
  //   // localStorage.setItem('campaignData', JSON.stringify(campaignsData));
  //   // console.log('Campaign data set in local storage:', campaignsData);

  //   fetchCampaigns();
  // }, []);

  // Function to sum the values of email_status_count
  const calculateEmailStats = (emailStatusCount) => {
    const total = Object.values(emailStatusCount).reduce((acc, count) => acc + count, 0);
    return {
      sent: emailStatusCount["Sent"] || 0, // Assuming "Sent" is the sent count
      total: total,
    };
  };

  useEffect(() => {
    // Update email stats from campaignsData (example)
    if (campaignsData && campaignsData.email_status_count) {
      const emailStats = calculateEmailStats(campaignsData.email_status_count) || 0;
      setEmailStats(emailStats);
    }

    // Fetch and set campaigns data
    if (campaignsData) {
      // setCampaigns(campaignsData.num_of_campaigns || 0);
      setCampaignsStats(campaignsData.num_of_campaigns || 0);
      setCompanyStats(campaignsData.num_of_companies ||0)
      // setSubscribersStats(campaignsData.num_of_subscribers || 0);
    }
  }, [campaignsData]); // Re-run when campaignsData changes

  return (
    <div className="px-4 md:px-20 lg:px-40 py-1 bg-grey">
      <div className="py-4 md:py-8 flex flex-col md:flex-row justify-between items-center mb-2">
        <div className="font-bold text-xl md:text-2xl mb-4 md:mb-0">Dashboard</div>
        <Button onClick={() => newCampaign(navigate)} className="text-white px-4 py-2 rounded-full" text="+ Create New Campaign" />

      </div>
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-5 justify-center">
        <div className="flex items-center p-4 bg-white rounded-md shadow-md w-full md:w-1/3">
          <img src={IconMail} alt="Email Icon" className="w-14 h-14 mr-4 ml-5" />
          <div>
            <div className="text-sm">Email Sent</div>
            <div className="font-bold text-xl md:text-3xl">{`${emailStats.sent}/${emailStats.total}`}</div>
          </div>
        </div>

        <div className="flex items-center p-4 bg-white rounded-md shadow-md w-full md:w-1/3">
          <img src={IconCampaign} alt="Campaign Icon" className="w-14 h-14 mr-4 ml-5" />
          <div>
            <div className="text-sm">Campaigns</div>
            <div className="font-bold text-xl md:text-3xl">{campaignsStats}</div>
          </div>
        </div>

        <div className="flex items-center p-4 bg-white rounded-md shadow-md w-full md:w-1/3">
          <img src={IconHeart} alt="Subscribers Icon" className="w-14 h-14 mr-4 ml-5" />
          <div>
            <div className="text-sm">Company</div>
            <div className="font-bold text-xl md:text-3xl">{companyStats}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DonutCharts = ({ sortBy, campaignsData  }) => {

  const getChartData = (sort, campaignsDataSum) => {
    // Dummy data based on sort type
    if (sort === 'date') {
      return {
        progress: [
          { name: 'Sent', value: campaignsDataSum.email_status_count['Sent'] || 0},
          { name: 'In Progress', value: campaignsDataSum.email_status_count['Read'] + campaignsDataSum.email_status_count['Waiting to review'] || 0},
          { name: 'Not Started', value: campaignsDataSum.email_status_count['Not started']},
        ],
        status: [
          { name: 'Replied', value: campaignsDataSum.email_status_count['Replied']},
          { name: 'Read', value: campaignsDataSum.email_status_count['Read']},
          { name: 'Waiting to Review', value: campaignsDataSum.email_status_count['Waiting to review']},
        ]
      };
    } else {
      return {
        progress: [
          { name: 'Sent', value: campaignsDataSum.email_status_count['Sent'] || 0},
          { name: 'In Progress', value: campaignsDataSum.email_status_count['Read'] + campaignsDataSum.email_status_count['Waiting to review'] || 0},
          { name: 'Not Started', value: campaignsDataSum.email_status_count['Not started']},
        ],
        status: [
          { name: 'Replied', value: campaignsDataSum.email_status_count['Replied']},
          { name: 'Read', value: campaignsDataSum.email_status_count['Read']},
          { name: 'Waiting to Review', value: campaignsDataSum.email_status_count['Waiting to review']},
        ]
      };
    }
  };

  const data = getChartData(sortBy, campaignsData);
  const ProgressColor = ['#6ceac0', '#ea6c6c', '#0da5e9'];
  const CampaignStatusColor = ['#00b503', '#e9001c', '#ffdb20'];

  return (
    <div className="py-5 md:py-10 flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 md:space-x-8 bg-white p-4 rounded shadow-md">
      <div className="w-full md:w-1/2 h-[300px]">
        <h3 className="text-center font-bold mb-2">Progress</h3>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data.progress}
              cx="50%"
              cy="50%"
              outerRadius="80%"
              innerRadius="50%"
              fill="#8884d8"
              dataKey="value"
            >
              {data.progress.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={ProgressColor[index % ProgressColor.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend layout="vertical" align="right" verticalAlign="middle" />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="w-full md:w-1/2 h-[300px]">
        <h3 className="text-center font-bold mb-2">Campaign Status</h3>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data.status}
              cx="50%"
              cy="50%"
              outerRadius="80%"
              innerRadius="50%"
              fill="#8884d8"
              dataKey="value"
            >
              {data.status.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={CampaignStatusColor[index % CampaignStatusColor.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend layout="vertical" align="right" verticalAlign="middle" />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const SortByDropdown = ({ onSortChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState('Date');

  const handleSort = (sort) => {
    setSelectedSort(sort);
    setIsOpen(false);
    onSortChange(sort.toLowerCase());
  };

  return (
    <div className="relative inline-block text-left w-40">
      <div>
        <button
          type="button"
          className="inline-flex justify-between items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          Sortby
          <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {['Date', 'Campaign name'].map((option) => (
              <a
                key={option}
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                role="menuitem"
                onClick={() => handleSort(option)}
              >
                {option}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ChartPanel = ({campaignsData}) => {
  const [sortBy, setSortBy] = useState('date');

  return (
    <div className="px-4 md:px-20 lg:px-40 bg-grey py-5">
      <div className="mb-4 flex justify-start">
        <SortByDropdown onSortChange={setSortBy} />
      </div>
      <DonutCharts sortBy={sortBy} campaignsData={campaignsData} />
    </div>
  );
};

const MainBody = ({ campaignsData }) => {
  return (
    <div className="flex flex-col space-y-1 flex-grow">
      {/* Pass campaignsData to StatisticPanel and ChartPanel */}
      <StatisticPanel campaignsData={campaignsData} />
      <ChartPanel campaignsData={campaignsData} />
    </div>
  );
};

const Dashboard = () => {
  const roboman_api = process.env.REACT_APP_ROBOMAN_API;
  const [campaignsData, setCampaignsData] = useState({
    num_of_campaigns: 0,
    num_of_companies: 0,
    email_status_count: {}
  });

  const fetchUserDataFromAPI = async (access_token) => {
    try {
      const response = await fetch(`${roboman_api}/users/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: access_token,
        },
      });

      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };

  const fetchCampaignsSummaryData = async (access_token) => {
    try {
      const response = await fetch(`${roboman_api}/campaign/status/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: access_token,
        },
      });

      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
        return null;
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching campaigns data:', error);
      return null;
    }
  };

  const fetchCampaignData = async () => {
    const access_token = localStorage.getItem('access_token');
    const userData = await fetchUserDataFromAPI(access_token);

    if (userData.user_info) {
      
      console.log('Account has data');
      // saveUserData(userData)
      localStorage.setItem('email', userData.email);
      localStorage.setItem('nickname', userData.user_info.nick_name);
      localStorage.setItem('companyType', userData.user_info.company_type);
      localStorage.setItem('linkedinUrl', userData.user_info.linkedin_address);
      localStorage.setItem('userType', userData.user_info.user_type);
      localStorage.setItem('havedata', JSON.stringify(true));
      if (userData.unipile_account_id !== null) {
        localStorage.setItem('lnk_act', JSON.stringify(true));
      } else {
        localStorage.setItem('lnk_act', JSON.stringify(false));
      }
 
    } else {
      console.log('Account has no data');
      localStorage.setItem('havedata', JSON.stringify(false));
      localStorage.setItem('lnk_act', JSON.stringify(false));
    }

    // Fetch campaigns data
    const campaignsDataSum = await fetchCampaignsSummaryData(access_token);
    if (campaignsDataSum) {
      setCampaignsData({
        num_of_campaigns: campaignsDataSum.num_of_campaigns,
        num_of_companies: campaignsDataSum.num_of_companies,
        email_status_count: campaignsDataSum.email_status_count,
      });
    }
    // console.log("data from API", campaignsData)

  };

  useEffect(() => {
    fetchCampaignData();
  }, [])

  return (
    <div className="min-h-screen h-screen w-screen flex flex-col bg-gray-200">
      <HeaderMainPage />
      <MainBody campaignsData={campaignsData} />
    </div>
  );
};

export default Dashboard;
