import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {OnboardingHeaderPart, LargeImagePlaceholder} from '../../header/OnboardingHeader';
import individualImage from '../../assets/individual.png';
import companyImage from '../../assets/company.png';
import video1 from '../../assets/page1_female_openai.mp4';
import { useVoiceInput } from './VoiceInput';

const ClassifyCustomer = () => {
  const [selectedType, setSelectedType] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectbyvoice, setSelectByVoice] = useState(false);
  const navigate = useNavigate();
  const roboman_api = process.env.REACT_APP_ROBOMAN_API;

  //
  const fetchUserDataFromAPI = async (access_token) => {
    try {
      const response = await fetch(`${roboman_api}/users/me`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': access_token,
          },

      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      const data = await response.json();
      // localStorage.setItem('data_user', data);
      console.log(data.user_info)
      // console.log(response)
      return data.user_info;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };

  const fetchData = async () => {
    // Assume we have the user's email from somewhere (e.g., context, props, or localStorage)
    // const userEmail = localStorage.getItem('userEmail')
    const access_token = localStorage.getItem('access_token')
    // Fetch data from API
    const userData = await fetchUserDataFromAPI(access_token);
    
    if (userData) {
      // If API fetch is successful, use the data
      console.log("Account have data")
      localStorage.setItem('nickname', userData.nick_name);
      localStorage.setItem('companyType', userData.company_type);
      localStorage.setItem('linkedinUrl', userData.linkedin_address);
      navigate('/campinitselect');
      

    } else {
      // If API fetch fails, load from localStorage
      console.log("Account have data")
      navigate('/onboarding-input');
    }
  };
  //

  const {
    startListening,
    stopListening,
    getTranscript,
    isListening,
    browserSupportsSpeechRecognition,
    resetTranscript
  } = useVoiceInput();

  const handleTypeSelection = (type) => {
    setSelectedType(prevType => prevType === type ? null : type);
  };

  const handleContinue = async () => {
    if (!selectedType) {
      setShowPopup(true);
      return;
    }
    localStorage.setItem('userType', selectedType);

    // fetchData();


    navigate('/onboarding-input');
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    console.log(getTranscript());
    const transcript = getTranscript().toLowerCase();

    if (transcript.includes('individual') && selectedType !== 'Individual') {
      handleTypeSelection('Individual');
      stopListening();
      resetTranscript();
      setSelectByVoice(true)
    } else if (transcript.includes('business') && selectedType !== 'Business') {
      handleTypeSelection('Business');
      stopListening();
      resetTranscript();
      setSelectByVoice(true)
    }

    console.log(selectedType);

    // Delay and call handleContinue after type selection
    if (selectedType && selectbyvoice) {
      const timer = setTimeout(() => {
        handleContinue();
        
      }, 1000);

      // Clear timeout if selectedType changes
      return () => clearTimeout(timer);
    }
    
  }, [getTranscript, stopListening, resetTranscript, selectedType]);

  const handleVoiceButtonClick = () => {
    if (isListening) {
      stopListening();
    } else {
      startListening(false);
      
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white relative">
      <OnboardingHeaderPart />

      <div className="flex justify-center items-start flex-grow pt-10 overflow-auto bg-gray-100">
        <div className="flex flex-col lg:flex-row w-full max-w-[1200px] min-h-[600px] lg:justify-center items-center">
          <LargeImagePlaceholder videomain={video1} />

          {/* Existing content */}
          <div className="lg:w-[600px] lg:h-[600px] bg-gray-100 lg:mr-10">
            <div className="bg-white p-8 rounded-lg shadow-md w-full h-full">
              <h2 className="text-2xl font-bold mb-10 text-center">Join us as a business or an individual</h2>

              <div className="flex justify-center space-x-10 mb-6">
                <button
                  className={`buttonleft w-[220px] h-[380px] flex flex-col items-center justify-center p-4 rounded-lg bg-gray-100 hover:bg-gray-300 transition-all relative ${
                    selectedType === 'Individual' ? 'border-2 border-blue-500' : ''
                  }`}
                  onClick={() => handleTypeSelection('Individual')}
                >
                  <img src={individualImage} alt="Individual" className="w-[170px] h-[160px] object-contain mb-2" />
                  <span className='font-bold mt-10'>Individual</span>
                  {selectedType === 'Individual' && (
                    <span className="absolute bottom-2 right-2 bg-blue-500 rounded-full p-1">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                    </span>
                  )}
                </button>
                <button
                  className={`buttonright w-[220px] h-[380px] flex flex-col items-center justify-center p-4 rounded-lg bg-gray-100 hover:bg-gray-300 transition-all relative ${
                    selectedType === 'Business' ? 'border-2 border-blue-500' : ''
                  }`}
                  onClick={() => handleTypeSelection('Business')}
                >
                  <img src={companyImage} alt="Business" className="w-[170px] h-[160px] object-contain mb-2" />
                  <span className='font-bold mt-10'>Business</span>
                  {selectedType === 'Business' && (
                    <span className="absolute bottom-2 right-2 bg-blue-500 rounded-full p-1">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                    </span>
                  )}
                </button>
              </div>

              <div className="flex justify-center mt-6">
                <div className="flex items-center gap-4">
                  <button
                    className="w-[100px] h-[40px] bg-[#223F9E] text-white font-bold rounded-full hover:bg-blue-700 transition-colors"
                    onClick={handleContinue}
                  >
                    Continue
                  </button>
                  
                  {browserSupportsSpeechRecognition && (
                    <button
                      className={`w-[40px] h-[40px] ${
                        isListening ? 'bg-red-500' : 'bg-[#223F9E]'
                      } text-white rounded-full flex items-center justify-center hover:opacity-90 transition-colors`}
                      onClick={handleVoiceButtonClick}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="bg-white border border-gray-300 rounded-lg p-6 shadow-lg w-[400px] z-10 relative">
            <p className="text-lg font-semibold mb-4">Attention</p>
            <p className="text-base mb-4">Please choose one option to optimize AI.</p>
            <div className="flex justify-center">
              <button
                onClick={closePopup}
                className="px-4 py-2 bg-[#223F9E] text-white font-bold rounded-full hover:bg-blue-700 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}


    </div>
  );
};

export default ClassifyCustomer;