import React from 'react';
// Import the JSON data from disc_info.json
import discInfo from '../../assets/disc_info.json'; // Adjust the path based on your folder structure

function ExplainDISC({ isOpen, closeModal }) {
  if (!isOpen) return null; // Don't render modal if it's not open

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 md:w-1/2">
        <h1 className="text-2xl font-bold mb-4">DISC Profile Explain</h1>
        <p className="text-lg mb-4">
          DISC is a behavioral assessment tool used to understand human behavior.
          It categorizes individuals into four primary traits: Dominance, Influence, Steadiness, and Calculativeness,
          with an additional focus on Non-Negativity for emotional stability.
        </p>

        <table className="min-w-full table-auto border-collapse">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 border border-gray-300">Abbreviation</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 border border-gray-300">Term</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 border border-gray-300">Description</th>
            </tr>
          </thead>
          <tbody>
            {discInfo.disc.map((item, index) => (
              <tr key={index}>
                <td className="px-4 py-2 text-sm border border-gray-300">{item.abbreviation}</td>
                <td className="px-4 py-2 text-sm border border-gray-300">{item.trait}</td>
                <td className="px-4 py-2 text-sm border border-gray-300">{item.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
        
        {/* Center the Close button */}
        <div className="flex justify-center mt-4">
          <button
            onClick={closeModal}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default ExplainDISC;
