import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import whiteLogo from '../assets/Logo_white.png';
import profileImage from '../assets/profile.png';
import Swal from 'sweetalert2';


export const newCampaign = (navigate) => {

  
  const keysToKeep = [
    "access_token",
    "isLoggedIn",
    "havedata",
    "nickname",
    "linkedinUrl",
    "email",
    "userType",
    "lnk_act",
    "page3_audio_data"

  ]; // Specify the keys you want to keep
  
  // Collect all keys into an array
  const allKeys = Object.keys(localStorage);
  
  // Iterate over all keys
  allKeys.forEach((key) => {
    // If the key is not in the keysToKeep array, remove it
    if (!keysToKeep.includes(key)) {
      localStorage.removeItem(key);
    }
  });
  
  // Explicitly remove specific items if needed
  localStorage.removeItem("conversation_id");
  const haveData = JSON.parse(localStorage.getItem('havedata'));
  const haveLinkedIN = JSON.parse(localStorage.getItem('lnk_act'));
  if (haveData && haveLinkedIN){
    navigate('/campinitselect');
  }else{
    navigate('/onboarding-input');
  }
};

const HeaderMainPage = () => {
  const navigate = useNavigate();
  const [showCampaignMenu, setShowCampaignMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showUserAccountPopup, setShowUserAccountPopup] = useState(false);
  const campaignMenuRef = useRef(null);
  const profileMenuRef = useRef(null);
  
  // const [linkedinPassword, setLinkedinPassword] = useState('password');
  // const [showPassword, setShowPassword] = useState(false);
  // const [loginEmail, setLoginEmail] = useState('')

  const commonButtonStyle = "bg-transparent py-2 px-4 text-white cursor-pointer text-lg font-semibold hover:bg-blue-700 transition-colors duration-200 rounded-full";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (campaignMenuRef.current && !campaignMenuRef.current.contains(event.target)) {
        setShowCampaignMenu(false);
      }
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const Dashboard = () => {
    navigate('/dashboard');
    // Implementation to be added later
  };

  const myCampaign = () => {
    localStorage.removeItem("campaign_id");
    navigate('/my-campaign');

  };

  const toggleCampaignMenu = () => {
    setShowCampaignMenu(!showCampaignMenu);
    setShowProfileMenu(false);
  };

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
    setShowCampaignMenu(false);
  };

  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef(null);
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsNavOpen(false);
    }
  };

  useEffect(() => {
    if (isNavOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNavOpen]);

  // User Account Popup Component
  const UserAccountPopup = ({ onClose }) => {
    
    const roboman_api = process.env.REACT_APP_ROBOMAN_API;
    // Predefined options for dropdowns
    const USER_TYPES = [
      'Business',
      'Individual'
    ];

    const COMPANY_TYPES = [
      'Partnership',
      'Limited Liability Partnership (LLP)',
      'Private Limited Company (Ltd)',
      'Public Limited Company (PLC)',
      'Umbrella Company'
    ];

    // State to manage form fields
    const [userData, setUserData] = useState({
      nickname: '',
      email: '',
      userType: '',
      linkedinUrl: '',
      companyType: ''
    });

    // Load data from local storage on component mount
    useEffect(() => {
      setUserData({
        nickname: localStorage.getItem('nickname') || '',
        email: localStorage.getItem('email') || '',
        userType: localStorage.getItem('userType') || '',
        linkedinUrl: localStorage.getItem('linkedinUrl') || '',
        companyType: localStorage.getItem('companyType') || ''
      });
    }, []);

    // Handle input changes
    const handleInputChange = (field, value) => {
      setUserData(prev => ({
        ...prev,
        [field]: value
      }));
    };

    // Handle form submission
    const handleSubmit = async () => {
      try {
        // Prepare request payload
        const payload = {
          nick_name: userData.nickname,
          user_type: userData.userType,
          billing_plan: "None",
          company_type: userData.companyType,
          linkedin_address: userData.linkedinUrl
        };

        // Simulate API call
        const response = await fetch(`${roboman_api}/users/me`, {
          method: 'PUT',
          headers: {
            'accept': 'application/json',
            'Authorization': localStorage.getItem('access_token'),
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });

        const result = await response.json();

        if (result.status === 'success') {
          // Show success alert
          await Swal.fire({
            icon: 'success',
            title: 'Update Successful!',
            text: 'Your account information has been updated.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });

          // Update local storage
          Object.entries(userData).forEach(([key, value]) => {
            localStorage.setItem(key, value);
          });

          // Close popup
          onClose();
        } else {
          // Show error alert
          await Swal.fire({
            icon: 'error',
            title: 'Update Failed',
            text: result.message || 'There was an issue updating your account. Please try again.',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Try Again'
          });
          onClose();
        }
      } catch (error) {
        // Show error alert for network or unexpected errors
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred. Please try again later.',
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
        console.error('Update error:', error);
      }
    };

    const authorizeLinkedInConnection = async () => {
      const access_token = localStorage.getItem('access_token');
      const headers = {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': access_token, // Replace with the actual token
      };
  
      try {
        const response = await fetch(`${roboman_api}/authorize-linkedin-connection`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            success_redirect_url: "https://roboman.intuicon.ai/dashboard",
            failure_redirect_url: "https://roboman.intuicon.ai/dashboard"
          }),
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log(data)
          if (data.object === 'HostedAuthUrl' && data.url) {
            // Redirect to the URL provided in the response
            window.location.href = data.url;
          } else {
            // Navigate to "/campinit" if response does not contain the expected data
            navigate('/dashboard');
          }
        } else {
          // Handle non-OK responses
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Error during authorization:', error);
        navigate('/dashboard'); // Navigate to fallback page in case of error
      }
    };

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-xl w-full max-w-md p-6 text-black">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold text-black">User Account Information</h2>
            <button 
              onClick={onClose} 
              className="text-gray-500 hover:text-gray-700"
            >
              ✖
            </button>
          </div>

          <div className="space-y-4">
            {/* User Name */}
            <div>
              <label className="block text-sm font-medium text-black">User Name</label>
              <input
                type="text"
                value={userData.nickname}
                onChange={(e) => handleInputChange('nickname', e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-black"
              />
            </div>

            {/* Email */}
            <div>
              <label className="block text-sm font-medium text-black">Email</label>
              <input
                type="email"
                value={userData.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-black"
                disabled
              />
            </div>

            {/* User Type Dropdown */}
            <div>
              <label className="block text-sm font-medium text-black">User Type</label>
              <select
                value={userData.userType}
                onChange={(e) => handleInputChange('userType', e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-black"
              >
                {/* <option value="">Select User Type</option> */}
                {USER_TYPES.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>

            {/* LinkedIn */}
            <div>
              <label className="block text-sm font-medium text-black">LinkedIn</label>
              <input
                type="text"
                value={userData.linkedinUrl}
                onChange={(e) => handleInputChange('linkedinUrl', e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-black"
              />
            </div>

            {/* LinkedIn Login Email */}
            {/* <div>
              <label className="block text-sm font-medium text-black">LinkedIn Email Login</label>
              <input
                type="text"
                value={"email name"}
                // onChange={(e) => handleInputChange('linkedinUrl', e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-black"
              />
            </div> */}

            {/* LinkedIn Password */}
            {/* <div>
              <label className="block text-sm font-medium text-black">LinkedIn Password</label>
              <input
                type={showPassword ? "text" : "password"}
                value={linkedinPassword}
                onChange={setLinkedinPassword(linkedinPassword)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-black"
              />
            </div> */}

            {/* Company Type Dropdown */}
            <div>
              <label className="block text-sm font-medium text-black">Company Type</label>
              <select
                value={userData.companyType}
                onChange={(e) => handleInputChange('companyType', e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-black"
              >
                {/* <option value="">Select Company Type</option> */}
                {COMPANY_TYPES.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>

            {/* LinkedIn Connection Status */}
            <div className="mt-4">
              <label className="block text-sm font-medium text-black">LinkedIn Connection Status</label>
              <div className="flex items-center justify-between mt-1 space-x-4">
                  {/* Connection Status */}
                  <div
                    className={`p-2 rounded-md font-medium text-center ${
                      localStorage.getItem("lnk_act") === "true"
                        ? "bg-green-100 text-green-800 border border-green-300"
                        : "bg-red-100 text-red-800 border border-red-300"
                    }`}
                    style={{ flex: "1 1 0", height: "40px" }} // Ensure equal size
                  >
                    {localStorage.getItem("lnk_act") === "true" ? "Connected" : "Not Connected"}
                  </div>

                  {/* Connect Button */}
                  {!localStorage.getItem("lnk_act") === "true" && (
                    <button
                      onClick={authorizeLinkedInConnection} // Replace with your actual connection logic
                      className="rounded-md bg-blue-500 text-white font-medium hover:bg-blue-600"
                      style={{ flex: "1 1 0", height: "40px" }} // Ensure equal size
                    >
                      Connect
                    </button>
                  )}
              </div>
            </div>
          </div>

          

          <div className="flex justify-end space-x-2 mt-6">
            <button 
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-black hover:bg-gray-50"
            >
              Cancel
            </button>
            <button 
              onClick={handleSubmit}
              className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Open user account popup
  const openUserAccountPopup = () => {
    setShowUserAccountPopup(true);
    setShowProfileMenu(false);
  };

  return (
    <header className="w-full h-20 flex justify-between items-center px-4 md:px-12 bg-[#223F9E] text-white">
      <div className="flex-none w-[100px] md:w-[150px] flex justify-start items-center">
        <img src={whiteLogo} alt="White Logo" className="w-[80px] md:w-[130px] h-[40px] md:h-[50px] object-contain" />
      </div>

      <button className="md:hidden text-2xl focus:outline-none" onClick={toggleNav}>
        ☰
      </button>

      <nav className="hidden md:flex flex-1 justify-center items-center space-x-5">
        <button onClick={Dashboard} className={commonButtonStyle}>Dashboard</button>
        <div className="relative" ref={campaignMenuRef}>
          <button onClick={toggleCampaignMenu} className={commonButtonStyle}>
            Campaign <span className="ml-1">▼</span>
          </button>
          {showCampaignMenu && (
            <div className="absolute top-full left-0 bg-white border border-gray-200 rounded-md shadow-lg z-10 mt-1 min-w-[170px]">
              <button onClick={() => newCampaign(navigate)} className="block w-full h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-t-md font-semibold">New Campaign</button>
              <button onClick={myCampaign} className="block w-full h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-b-md font-semibold">List of Campaign</button>
            </div>
          )}
        </div>
      </nav>

      {/* Mobile Menu */}
      {isNavOpen && (
        <div ref={navRef} className="absolute top-20 left-0 w-full bg-[#223F9E] text-white md:hidden">
          <div className="flex flex-col items-center space-y-2 py-4">
            <button onClick={Dashboard} className={commonButtonStyle}>Dashboard</button>
            <button onClick={toggleCampaignMenu} className={commonButtonStyle}>
              Campaign <span className="ml-1">▼</span>
            </button>
            {showCampaignMenu && (
              <div className="bg-white border border-gray-200 rounded-md shadow-lg z-10 mt-10 min-w-[170px]">
                <button onClick={() => newCampaign(navigate)} className="block w-full py-2 px-4 text-left hover:bg-gray-100 text-gray-700 font-semibold">New Campaign</button>
                <button onClick={myCampaign} className="block w-full py-2 px-4 text-left hover:bg-gray-100 text-gray-700 font-semibold">List of Campaign</button>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex-none w-[100px] md:w-[150px] flex justify-end items-center relative" ref={profileMenuRef}>
        <div className="flex items-center cursor-pointer" onClick={toggleProfileMenu}>
          <img src={profileImage} alt="Profile" className="w-8 md:w-10 h-8 md:h-10 rounded-full object-cover mr-1" />
          <span className="text-xs ml-1">▼</span>
        </div>
        {showProfileMenu && (
          <div className="absolute top-full right-0 bg-white border border-gray-200 rounded-md shadow-lg z-10 mt-1">
            <button className="block w-[150px] md:w-[180px] h-[40px] md:h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-b-md font-semibold" onClick={openUserAccountPopup}>User Account</button>
            <button className="block w-[150px] md:w-[180px] h-[40px] md:h-[50px] py-2 px-4 text-left hover:bg-gray-100 text-gray-700 rounded-b-md font-semibold" onClick={handleLogout}>Logout</button>
          </div>
        )}

      {/* User Account Popup */}
      {showUserAccountPopup && (
        <UserAccountPopup 
          onClose={() => setShowUserAccountPopup(false)} 
        />
      )}

      </div>
    </header>
  );
};

export default HeaderMainPage;
