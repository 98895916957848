import React, { useEffect, useState } from 'react';
import {OnboardingHeaderPart} from '../../header/OnboardingHeader';
import { useNavigate } from 'react-router-dom';
import discInfo from '../../assets/disc_info.json';
import oceanInfo from '../../assets/ocean_info.json';
import Swal from 'sweetalert2';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const AnalyzingUser = () => {
    const navigate = useNavigate();
    //
    const [isLoading, setLoading] = useState(true);
    const [isDataLoad, setDataLoad] = useState(false);
    //
    const [userData, setUserData] = useState(null);
    const apikey = process.env.REACT_APP_HUMANTICAI_KEY;
    const linkedinUrl = localStorage.getItem("LinkedInlink")
    const [currentIndex, setCurrentIndex] = useState(0);

    

    const handlingFetchLinkedIn = async (apikey, linkedinUrl) => {
        try {
          const response = await fetch(`https://api.humantic.ai/v1/user-profile?apikey=${apikey}&id=${linkedinUrl}&persona=sales`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            console.error('API is not running');
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error('Error fetching LinkedIn data:', error);
          return null;
        }
      };

    const createUserData = (data) => ({
        user_profile_image: data.user_profile_image || '',
        display_name: data.display_name || '',
        work_history: data.work_history?.length > 0 ? data.work_history[0].title : 'unemployed',
        location: data.location || '',
        skills: data.skills || [],
        disc_description: data.personality_analysis?.summary?.disc?.description || '',
        ocean_description: data.personality_analysis?.summary?.ocean?.description || [],
        disc_label: data.personality_analysis?.summary?.disc?.label || [],
        ocean_label: data.personality_analysis?.summary?.ocean?.label || [],
        persona: data.persona.sales.email_personalization|| [],
        cold_calling_advice: data.persona.sales.cold_calling_advice || [],
        communication_advice: data.persona.sales.communication_advice || [],
        
    });

    // useEffect(() => {
    //     const loadUserData = async () => {
    //         const storedData = localStorage.getItem('LinkedIn_link');
    //         if (storedData && storedData !== "undefined") {
    //             console.log("founding data");
    //             try {
    //                 const parsedData = JSON.parse(storedData);
    //                 setUserData(createUserData(parsedData));
    //                 // console.log(parsedData);
    //             } catch (error) {
    //                 console.error('Error parsing LinkedIn data:', error);
    //             }
    //         } else {
    //             console.log("not founding data");
    //             try {
    //                 setIsLoading(true); // Start loading
    //                 const fetchedData = await handlingFetchLinkedIn(apikey, linkedinUrl);
    //                 if (fetchedData && fetchedData.results) {
    //                     // console.log(fetchedData.results);
    //                     // localStorage.setItem('linkedInResult', JSON.stringify(fetchedData.results));
    //                     setUserData(createUserData(fetchedData.results));
    //                 } else {
    //                     console.log("not founding data");

    //                     // Show SweetAlert to inform the user about the delay
    //                     // Swal.fire({
    //                     //     icon: 'info',
    //                     //     title: 'Processing',
    //                     //     text: 'The processing will take time. Please wait some more minutes and refresh the page.',
    //                     //     confirmButtonColor: "#223F9E",
    //                     //     confirmButtonText: 'Close'
    //                     // });

    //                     // Set a timer to wait for 3 minutes before attempting to fetch data
    //                     setTimeout(async () => {
    //                         try {
    //                             const fetchedData = await handlingFetchLinkedIn(apikey, linkedinUrl);
    //                             if (fetchedData && fetchedData.results) {
    //                                 // console.log(fetchedData.results);
    //                                 localStorage.setItem('linkedInResult', JSON.stringify(fetchedData.results));
    //                                 setUserData(createUserData(fetchedData.results));
    //                             } else {
    //                                 // If still no data, show SweetAlert
    //                                 Swal.fire({
    //                                     icon: 'info',
    //                                     title: 'Processing Delay',
    //                                     text: 'The processing will take time. Please try again later.',
    //                                     confirmButtonColor: "#223F9E",
    //                                     confirmButtonText: 'Close'
    //                                 });
    //                             }
    //                         } catch (error) {
    //                             console.error('Error fetching LinkedIn data:', error);
    //                             setUserData(createUserData(null));
    //                             Swal.fire({
    //                                 icon: 'error',
    //                                 title: 'Error',
    //                                 text: 'An error occurred while fetching data. Please try again later.',
    //                                 confirmButtonColor: "#223F9E",
    //                                 confirmButtonText: 'Close'
    //                             });
    //                         }
    //                     }, 180000); // 3 minutes in milliseconds
    //                 }
    //             } catch (error) {
    //                 console.error('Error fetching LinkedIn data:', error);
    //                 Swal.fire({
    //                     icon: 'error',
    //                     title: 'Error',
    //                     text: 'An error occurred while fetching data. Please try again later.',
    //                     confirmButtonColor: "#223F9E",
    //                     confirmButtonText: 'Close'
    //                 });
    //             } finally {
    //                 setIsLoading(false); // Stop loading
    //             }
    //         }
    //     };

    //     loadUserData();
    // }, [apikey, linkedinUrl, userData]);
    const handleBack = () => {
        navigate('/email-status');
        localStorage.removeItem("LinkedInlink")
    };

    useEffect(() => {
        const loadUserData = async () => {
            const storedData = await handlingFetchLinkedIn(apikey, linkedinUrl);
            // const storedData = []
            console.log("data sample:", storedData)

            if (storedData && storedData !== "undefined") {

                if (storedData.status === "200") {
                    if (storedData.metadata.status==="FOUND")
                        {
                            console.log("founding data");
                    
                            setUserData(createUserData(storedData.results));
                            // console.log(parsedData);
                        }
                    else {
                        console.log("profile have add but is loading");
                        Swal.fire({
                            icon: 'info',
                            title: 'Processing',
                            text: 'The processing will take time. Please wait some more minutes and come back to this page later.',
                            confirmButtonColor: "#223F9E",
                            confirmButtonText: 'Close',
                            preConfirm: () => {
                                // Call the handleBack function here
                                handleBack();
                            }
                        });

                    }

                } else {
                    console.error('Profile not found');
                    // setUserData(createUserData(storedData.results));
                    // Show SweetAlert to inform the user about the delay
                    Swal.fire({
                        icon: 'error', // Changed to 'error' for error theme
                        title: 'Profile Not Found', // Adjusted the title for the error context
                        text: 'This profile is not found in our database or cannot be processed. Please try again later.', // Adjusted the error message
                        confirmButtonColor: "#223F9E",
                        confirmButtonText: 'Close',
                        preConfirm: () => {
                            // Call the handleBack function here
                            handleBack();
                        }
                    });

                }
            } else {
                const data = null;
                setUserData(createUserData(data));
                Swal.fire({
                    icon: 'error', // Changed to 'error' for error theme
                    title: 'Error Processing Profile', // Updated the title to reflect an error
                    text: 'There was an error while processing the profile. Please try again later.', // Adjusted the message to indicate an error
                    confirmButtonColor: "#223F9E",
                    confirmButtonText: 'Close',
                    preConfirm: () => {
                        // Call the handleBack function here
                        handleBack();
                    }
                });
            }
        };

        if (isDataLoad === false){
            loadUserData();
            setDataLoad(true);
            setLoading(false);
        }
    }, [apikey, linkedinUrl, isDataLoad]);
    
    const WelcomeString = () => {
        return (
        <div>
            <h2 className="text-2xl font-bold text-center">
            Your Profile, All Wrapped Up
            </h2>
        </div>
        );
    };

    const UserBasicInfo = () => {
        if (!userData) {
            return (
                <div className="w-full max-w-[1300px] h-auto flex flex-col md:flex-row p-6 bg-white items-center space-x-4 space-y-4 md:space-y-0 rounded-2xl shadow-md">
                    <div className="text-center text-gray-500 w-full">Data is loading</div>
                </div>
            );
        };

        return (
        <div className="w-full max-w-[1300px] h-auto flex flex-col md:flex-row p-6 bg-white items-center space-x-4 space-y-4 md:space-y-0 rounded-2xl shadow-md">
            {/* Avatar Logo Placeholder */}
            <div className="w-20 h-20 text-center bg-gray-300 flex items-center justify-center rounded-full ml-5 mr-5">
                {userData.user_profile_image ? (
                    <img src={userData.user_profile_image} alt="User Profile" className="w-full h-full object-cover rounded-full" />
                ) : (
                    "Avatar Logo"
                )}
            </div>

            <div className="flex flex-col space-y-2">
            <div className="text-lg md:text-xl font-bold">{userData.display_name}</div>
            <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4">
                {/* Gender */}
                <div className="flex items-center space-x-1">
                    {/* Note: Import Gender Logo Here */}
                    <div className="w-5 h-5 bg-gray-300 rounded-xl"></div>
                    <div className="px-2">{userData.work_history}</div>
                </div>
                {/* Location */}
                <div className="md:p-3 flex items-center space-x-1">
                    {/* Note: Import Location Logo Here */}
                    <div className="w-5 h-5 bg-gray-300 rounded-xl"></div>
                    <div className="px-2">{userData.location}</div>
                </div>
            </div>
            <div className="flex flex-wrap space-x-2">
                {userData.skills.map((skill, index) => (
                <div key={index} className="bg-blue-500 text-white px-2 py-1 rounded mb-2">
                    {skill}
                </div>
                ))}
            </div>
            </div>
        </div>
        );
    };

    const ContentBox = ({ title, content }) => {
        return (
          <div className="p-4 w-[250px] h-[280px] min-w-[250px] min-h-[280px] bg-gray-100 rounded-lg">
            <div className="text-blue-800 text-sm font-bold mb-2">{title}</div>
            <div className="text-sm mb-2">{content}</div>
          </div>
        );
      };

    const DiscProfile = () => {
        if (!userData) {
            return (
                <div className="p-3 w-full bg-white flex flex-col space-y-4 rounded-xl shadow-md items-center">
                    <div className="text-center text-gray-500">Data is loading</div>
                </div>
            );
        }

        const getDiscDescription = (label) => {
            const trait = discInfo.disc.find(t => t.abbreviation.toLowerCase() === label.toLowerCase());
            return trait ? trait.description : "Description not available";
        };

        return (
        <div className="p-3 w-full bg-white flex flex-col space-y-4 rounded-xl shadow-md items-center ">
            {/* Title */}
            <div className="text-center text-lg font-bold">Disc Profile</div>
            {/* Disc Label */}
            <div className="flex flex-wrap justify-center items-center space-x-2">
                {userData.disc_label.map((label, index) => (
                    <div key={index} className="bg-yellow-400 text-white py-1 px-4 rounded mb-2">
                        {label}
                    </div>
                ))}
            </div>
            {userData.disc_label.map((label, index) => (
                <ContentBox key={index} title={`${label} - ${discInfo.disc.find(t => t.abbreviation.toLowerCase() === label.toLowerCase())?.trait}`} content={getDiscDescription(label)} />
            ))}
        </div>
        );
    };

    const OceanProfile = () => {
        if (!userData) {
            return (
                <div className="p-3 bg-white flex flex-col space-y-4 rounded-xl h-full items-center shadow-md">
                    <div className="text-center text-gray-500">Data is loading</div>
                </div>
            );
        }

        const getOceanDescription = (label) => {
            const trait = oceanInfo.ocean.find(t => t.abbreviation.toLowerCase() === label.toLowerCase());
            return trait ? trait.description : "Description not available";
        };

        return (
        <div className="p-3 bg-white flex flex-col space-y-4 rounded-xl h-full items-center shadow-md ">
            {/* Title */}
            <div className="text-center text-lg font-bold">Ocean Profile</div>
            {/* OCEAN Label */}
            <div className="flex flex-wrap justify-center items-center space-x-2">
                {userData.ocean_label.map((label, index) => (
                    <div key={index} className="bg-yellow-400 text-white py-1 px-4 rounded">
                        {label}
                    </div>
                ))}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 md:gap-2 lg:gap-4 p-2">
                {userData.ocean_label.map((label, index) => (
                    <ContentBox key={index} title={`${label} - ${oceanInfo.ocean.find(t => t.abbreviation.toLowerCase() === label.toLowerCase())?.trait}`} content={getOceanDescription(label)} />
                ))}
            </div>
        </div>
        );
    };

    const ProfilesPart = () => {
        return (
          <div className="w-full max-w-[1300px] flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/3">
              <DiscProfile />
            </div>
            <div className="w-full md:w-2/3">
              <OceanProfile />
            </div>
          </div>
        );
    };

    const UserInfomationBoard = () => {
        return (
            <div className="flex-grow flex flex-col items-center space-y-8 py-4 px-4 md:px-8">
                {/* First Part */}
                <WelcomeString />
                {/* Second Part */}
                <UserBasicInfo />
                {/* Third Part */}
                <ProfilesPart />
            </div>
        );
    };

    const Footer = () => {
        return (
        <div className='bg-white flex justify-end shadow-2xl'>
            <div className="mb-3 mt-3">
                <button 
                className="bg-[#223F9E] text-white font-semibold py-2 px-10 mr-[50px] rounded-full"
                onClick={handleBack}
                >
                Back
                </button>
            </div>
        </div>
        )
    }




    const Representation = () => {

    }

    const EmailInstruction = () => {
        // Check if userData is null
        // console.log("User Data: ", userData.persona);
        if (!userData) {
            return (
                <div
                    className="flex items-center justify-center bg-white"
                    style={{
                        width: '90vw',
                        height: '90vh',
                        margin: 'auto',
                    }}
                >
                    {/* Placeholder for no data */}
                    <div className="text-gray-500 text-xl">Data is loading</div>
                </div>
            );
        }
    
        // Extract email personalization data
        const emailData = userData.persona;
    
        // UI for each cell in the grid
        const renderCell = (title, key) => (
            <div className="p-4 border rounded-lg shadow-md bg-gray-50">
                <h3 className="font-semibold text-lg">{title}</h3>
                <p className="text-sm text-gray-500">{emailData.definitions[key]}</p>
                <div className="mt-2">
                    <p className="font-bold">Advice:</p>
                    <p>{emailData.advice[key]}</p>
                </div>
                <div className="mt-2">
                    <p className="font-bold">Example:</p>
                    <p>{emailData.examples[key]}</p>
                </div>
            </div>
        );
    
        return (
            <div className="flex-grow flex flex-col items-center space-y-8 py-4 px-4 md:px-8 overflow-hidden">
                {/* Main container */}
                <div className="w-full max-w-[1300px] flex flex-col items-center p-6 bg-white rounded-2xl shadow-md">
                    {/* Title */}
                    <h1 className="text-2xl font-bold mb-6">Email Writing Suggestion</h1>
                    {/* Scrollable grid container */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full overflow-y-auto max-h-[70vh] p-2">
                        {renderCell("Subject", "Subject")}
                        {renderCell("Subject Length", "Subject Length")}
                        {renderCell("Salutation", "Salutation")}
                        {renderCell("Greeting", "Greeting")}
                        {renderCell("Emojis/GIFs", "Emojis/GIFs")}
                        {renderCell("Bullet Points", "Bullet Points")}
                        {renderCell("Closing Line", "Closing Line")}
                        {renderCell("Closing Greeting", "Closing Greeting")}
                        {renderCell("Complimentary Close", "Complimentary Close")}
                        {renderCell("Tone Of Words", "Tone Of Words")}
                    </div>
                </div>
            </div>
        );
    };    

    const InsightsSuggestion = () => {
        // Check if userData is null
        // console.log("User Data: ", userData.communication_advice)
        if (!userData) {
            return (
                <div
                    className="flex items-center justify-center bg-white"
                    style={{
                        width: '90vw',
                        height: '90vh',
                        margin: 'auto',
                    }}
                >
                    {/* Placeholder for no data */}
                    <div className="text-gray-500 text-xl">Data is loading</div>
                </div>
            );
        }
    
        // Extract communication advice data
        const adviceData = userData.communication_advice;
        const { what_to_say, what_to_avoid } = adviceData;
    
        return (
            <div className="flex-grow flex flex-col items-center space-y-8 py-4 px-4 md:px-8">
                {/* Main container */}
                <div className="w-full max-w-[1300px] flex flex-col items-center p-6 bg-white rounded-2xl shadow-md">
                    {/* Title */}
                    <h1 className="text-2xl font-bold mb-6">Insights Suggestion</h1>
                    
                    {/* Subtitle */}
                    <h2 className="text-lg font-medium text-gray-700 mb-4">
                        During A Call Or A Meeting
                    </h2>

                    {/* Grid layout */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
                        {/* DO's section */}
                        <div className="p-6 bg-green-500 text-white rounded-lg shadow-md">
                            <h3 className="font-semibold text-lg mb-4">DO's</h3>
                            <ul className="list-disc pl-4 space-y-2">
                                {what_to_say.map((text, index) => (
                                    <li key={index}>{text}</li>
                                ))}
                            </ul>
                        </div>
                        {/* DON'Ts section */}
                        <div className="p-6 bg-red-500 text-white rounded-lg shadow-md">
                            <h3 className="font-semibold text-lg mb-4">DON'Ts</h3>
                            <ul className="list-disc pl-4 space-y-2">
                                {what_to_avoid.map((text, index) => (
                                    <li key={index}>{text}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % components.length); // Cycle to the next component
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + components.length) % components.length); // Cycle to the previous component
    };

    const components = [
        <UserInfomationBoard key="userInfo" />,
        <EmailInstruction key="emailInstruction" />,
        <InsightsSuggestion key="insightsusgestion"/>
        // <AnotherComponent key="anotherComponent" />, // Add more components here as needed
        // Add more components if necessary
    ];

    return (

    <div>
        {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg">
                <div className="flex items-center space-x-2">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                    <span className="text-lg font-medium">Loading, please wait...</span>
                </div>
            </div>
        </div>
        ) : (
            <div className="flex flex-col min-h-screen w-full bg-gray-100">
                {/* This part is for the header section */}
                <OnboardingHeaderPart />
                {/* This part is for the main/body section */}
                {/* <UserInfomationBoard /> */}

                {/* Main Section */}
                <div className="flex-1 flex items-center justify-center relative">
                    {/* Previous Button */}
                    <button
                        onClick={handlePrevious}
                        className="absolute left-2 p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600"
                    >
                        <FaArrowLeft size={24} />
                    </button>

                    {/* Current Component */}
                    <div className="w-full max-w-[1300px] p-6">
                        {components[currentIndex]}
                    </div>

                    {/* Next Button */}
                    <button
                        onClick={handleNext}
                        className="absolute right-2 p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600"
                    >
                        <FaArrowRight size={24} />
                    </button>
                </div>
                {/* Footer is placed here to ensure it stays at the bottom */}
                <Footer />
            </div>
        )}
    </div>   

  );
};

export default AnalyzingUser;
